import mapValues from "lodash/mapValues";

const ACTIONS = {
  APP_RESET: null,

  USER_REQUEST: null,
  USER_SUCCESS: null,
  USER_ERROR: null,

  CUSTOMER_REQUEST: null,
  CUSTOMER_SUCCESS: null,
  CUSTOMER_DETAIL_SUCCESS: null,
  CUSTOMER_ERROR: null,

  DRIVER_REQUEST: null,
  DRIVER_SUCCESS: null,
  DRIVER_DETAIL_SUCCESS: null,
  CAR_TYPE_SUCCESS: null,
  DRIVER_ERROR: null,
  DRIVER_DOCUMENTS: null,

  RIDE_REQUEST: null,
  RIDE_SUCCESS: null,
  RIDE_ERROR: null,

  CONFIG_REQUEST: null,
  CONFIG_SUCCESS: null,
  CONFIG_ERROR: null,

  AD_REQUEST: null,
  AD_SUCCESS: null,
  AD_ERROR: null,
  AD_IMAGES: null
};

export default mapValues(ACTIONS, (v, k) => k);
