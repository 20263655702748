import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import { Link } from "react-router-dom";

class Icons_mobirise extends Component {
  render() {
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Examples</h4>
                    <p className="text-muted m-b-30 font-14">
                      Use{" "}
                      <code>&lt;i className="mbri-mobirise"&gt;&lt;/i&gt;</code>
                      .
                    </p>

                    <div className="row icon-demo-content">
                      <div className="col-12">
                        <h6 className="m-b-20">Mobile-friendly</h6>
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-mobirise" /> mbri-mobirise
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-mobile" /> mbri-mobile
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-mobile2" /> mbri-mobile2
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-tablet" /> mbri-tablet
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-tablet-vertical" />{" "}
                        mbri-tablet-vertical
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-watch" /> mbri-watch
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-devices" /> mbri-devices
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-laptop" /> mbri-laptop
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-desktop" /> mbri-desktop
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-responsive" /> mbri-responsive
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-touch-swipe" /> mbri-touch-swipe
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-touch" /> mbri-touch
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-browse" /> mbri-browse
                      </div>

                      <div className="col-12">
                        <h6 className="m-b-20">Shopping</h6>
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-shopping-bag" /> mbri-shopping-bag
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-shopping-basket" />{" "}
                        mbri-shopping-basket
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-shopping-cart" /> mbri-shopping-cart
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-cart-add" /> mbri-cart-add
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-cart-full" /> mbri-cart-full
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-cash" /> mbri-cash
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-delivery" /> mbri-delivery
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-credit-card" /> mbri-credit-card
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-sale" /> mbri-sale
                      </div>

                      <div className="col-12">
                        <h6 className="m-b-20">Navigation</h6>
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-left" /> mbri-left
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-right" /> mbri-right
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-up" /> mbri-up
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-down" /> mbri-down
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-arrow-prev" /> mbri-arrow-prev
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-arrow-next" /> mbri-arrow-next
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-arrow-up" /> mbri-arrow-up
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-arrow-down" /> mbri-arrow-down
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-plus" /> mbri-plus
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-close" /> mbri-close
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-menu" /> mbri-menu
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-success" /> mbri-success
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-play" /> mbri-play
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-more-horizontal" />{" "}
                        mbri-more-horizontal
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-more-vertical" /> mbri-more-vertical
                      </div>

                      <div className="col-12">
                        <h6 className="m-b-20">Symbols</h6>
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-home" /> mbri-home
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-star" /> mbri-star
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-hearth" /> mbri-hearth
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-idea" /> mbri-idea
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-magic-stick" /> mbri-magic-stick
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-flag" /> mbri-flag
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-target" /> mbri-target
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-speed" /> mbri-speed
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-help" /> mbri-help
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-letter" /> mbri-letter
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-info" /> mbri-info
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-question" /> mbri-question
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-features" /> mbri-features
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-briefcase" /> mbri-briefcase
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-sun" /> mbri-sun
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-sun2" /> mbri-sun2
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-smile-face" /> mbri-smile-face
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-sad-face" /> mbri-sad-face
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-alert" /> mbri-alert
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-timer" /> mbri-timer
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-clock" /> mbri-clock
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-growing-chart" /> mbri-growing-chart
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-cursor-click" /> mbri-cursor-click
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-protect" /> mbri-protect
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-camera" /> mbri-camera
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-globe" /> mbri-globe
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-globe-2" /> mbri-globe-2
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-bookmark" /> mbri-bookmark
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-rocket" /> mbri-rocket
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-paper-plane" /> mbri-paper-plane
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-hot-cup" /> mbri-hot-cup
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-calendar" /> mbri-calendar
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-search" /> mbri-search
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-zoom-out" /> mbri-zoom-out
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-android" /> mbri-android
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-apple" /> mbri-apple
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-windows" /> mbri-windows
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-bootstrap" /> mbri-bootstrap
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-github" /> mbri-github
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-like" /> mbri-like
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-wifi" /> mbri-wifi
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-gift" /> mbri-gift
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-database" /> mbri-database
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-paperclip" /> mbri-paperclip
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-print" /> mbri-print
                      </div>

                      <div className="col-12">
                        <h6 className="m-b-20">Editing</h6>
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-align-left" /> mbri-align-left
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-align-center" /> mbri-align-center
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-align-right" /> mbri-align-right
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-align-justify" /> mbri-align-justify
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-undo" /> mbri-undo
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-redo" /> mbri-redo
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-new-file" /> mbri-new-file
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-file" /> mbri-file
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-share" /> mbri-share
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-save" /> mbri-save
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-folder" /> mbri-folder
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-opened-folder" /> mbri-opened-folder
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-edit" /> mbri-edit
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-edit2" /> mbri-edit2
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-change-style" /> mbri-change-style
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-setting" /> mbri-setting
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-setting2" /> mbri-setting2
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-setting3" /> mbri-setting3
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-refresh" /> mbri-refresh
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-trash" /> mbri-trash
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-drag-n-drop" /> mbri-drag-n-drop
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-drag-n-drop2" /> mbri-drag-n-drop2
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-up-down" /> mbri-up-down
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-left-right" /> mbri-left-right
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-layers" /> mbri-layers
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-code" /> mbri-code
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-bold" /> mbri-bold
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-italic" /> mbri-italic
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-underline" /> mbri-underline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-add-submenu" /> mbri-add-submenu
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-bulleted-list" /> mbri-bulleted-list
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-numbered-list" /> mbri-numbered-list
                      </div>

                      <div className="col-12">
                        <h6 className="m-b-20">Site Elements</h6>
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-photo" /> mbri-photo
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-photos" /> mbri-photos
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-video-play" /> mbri-video-play
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-video" /> mbri-video
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-music" /> mbri-music
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-pin" /> mbri-pin
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-map-pin" /> mbri-map-pin
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-chat" /> mbri-chat
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-image-gallery" /> mbri-image-gallery
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-image-slider" /> mbri-image-slider
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-cust-feedback" /> mbri-cust-feedback
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-contact-form" /> mbri-contact-form
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-link" /> mbri-link
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-unlink" /> mbri-unlink
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-quote-left" /> mbri-quote-left
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-quote-right" /> mbri-quote-right
                      </div>

                      <div className="col-12">
                        <h6 className="m-b-20">Account</h6>
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-user" /> mbri-user
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-user2" /> mbri-user2
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-users" /> mbri-users
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-key" /> mbri-key
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-lock" /> mbri-lock
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-unlock" /> mbri-unlock
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-login" /> mbri-login
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-logout" /> mbri-logout
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-cloud" /> mbri-cloud
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-download" /> mbri-download
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-upload" /> mbri-upload
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-to-ftp" /> mbri-to-ftp
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-to-local-drive" />{" "}
                        mbri-to-local-drive
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-preview" /> mbri-preview
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-error" /> mbri-error
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-pages" /> mbri-pages
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-sites" /> mbri-sites
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-extension" /> mbri-extension
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-website-theme" /> mbri-website-theme
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="mbri-update" /> mbri-update
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default Icons_mobirise;
