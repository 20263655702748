import { produce } from "immer";
import ACTIONS from "../actions/types.actions";
const driver = produce((draft, action) => {
  switch (action.type) {
    case ACTIONS.DRIVER_REQUEST:
      draft.loading = true;
      return draft;
    case ACTIONS.DRIVER_SUCCESS:
      draft.loading = false;
      draft.data = action.response;
      return draft;
    case ACTIONS.DRIVER_DETAIL_SUCCESS:
      draft.loading = false;
      draft.detail = action.response;
      return draft;
    case ACTIONS.CAR_TYPE_SUCCESS:
      draft.carTypes = action.response;
      return draft;
    case ACTIONS.DRIVER_ERROR:
      draft.loading = false;
      draft.error = action.error;
      return draft;
    case ACTIONS.DRIVER_DOCUMENTS:
      draft.loading = false;
      draft.error = action.error;
      draft.documents = action.documents
      return draft;
    default:
      return draft;
  }
});

export default driver;
