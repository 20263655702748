import { produce } from "immer";
import ACTIONS from "../actions/types.actions";
const customer = produce((draft, action) => {
  switch (action.type) {
    case ACTIONS.CUSTOMER_REQUEST:
      draft.loading = true;
      return draft;
    case ACTIONS.CUSTOMER_SUCCESS:
      draft.loading = false;
      draft.data = action.response;
      return draft;
    case ACTIONS.CUSTOMER_DETAIL_SUCCESS:
      draft.loading = false;
      draft.detail = action.response;
      return draft;
    case ACTIONS.CUSTOMER_ERROR:
      draft.loading = false;
      draft.error = action.error;
      return draft;
    default:
      return draft;
  }
});

export default customer;
