import { toast } from "react-toastify";
import DataService from "../../services/data.service";
import { throwError, to } from "../../utils/utils";
import ACTIONS from "./types.actions";

export function configRequest() {
  return { type: ACTIONS.CONFIG_REQUEST };
}

export function configSuccess(response) {
  return { type: ACTIONS.CONFIG_SUCCESS, response };
}

export function configError(error) {
  return { type: ACTIONS.CONFIG_ERROR, error };
}

export function getConfigurations() {
  return async (dispatch) => {
    dispatch(configRequest());
    try {
      const [err, resp] = await to(DataService.getConfigurations());
      if (err) {
        const {status} = err.response;
        if(status === DataService.UNAUTHORIZED) {
          toast.error("Unauthorized or session timed out. Kindly relogin", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        } else {
          toast.error("Something went wrong", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        }
        throwError(err);
      }
      dispatch(configSuccess(resp.data.data[0] || {}));
      return;
    } catch (error) {
      dispatch(configError(error));
      throwError(error);
    }
  };
}

export function updateConfigurations(body) {
  return async (dispatch) => {
    dispatch(configRequest());
    try {
      const [err, resp] = await to(DataService.updateConfigurations(body));
      if (err) {
        const {status} = err.response;
        if(status === DataService.UNAUTHORIZED) {
          toast.error("Unauthorized or session timed out. Kindly relogin", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        } else {
          toast.error("Something went wrong", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        }
        throwError(err);
      }
      dispatch(getConfigurations());
      if(resp.data && resp.data.success) {
        toast.success("Config updated successfully!", {
          hideProgressBar: true,
          theme: "colored",
          closeButton: true,
          delay: 200
        });
      } else {
        toast.error("Something went wrong", {
          hideProgressBar: true,
          theme: "colored",
          closeButton: true,
          delay: 200
        });
      }
      return;
    } catch (error) {
      dispatch(configError(error));
      throwError(error);
    }
  };
}
