import ROUTES from "./constant.route";

const menu = [
  ROUTES.CUSTOMERS,
  ROUTES.DRIVERS,
  ROUTES.RIDES,
  ROUTES.CONFIGURATIONS,
  ROUTES.ADS,
  // ROUTES.NOTIFICATIONS,
];

export default menu;
