const ROUTES = {
  LOGIN: {
    name: "Login",
    title: "Login",
    path: "/login",
    icon: "",
  },
  HOME: {
    name: "Home",
    title: "Home",
    path: "/customers",
    icon: "mdi mdi-view-dashboard",
  },
  CUSTOMERS: {
    name: "Customers",
    title: "Customers",
    path: "/customers",
    icon: "mdi mdi-account",
  },
  CUSTOMERS_DETAIL: {
    name: "Customer",
    title: "Customer Details",
    path: "/customers/:id",
    icon: "mdi mdi-account",
  },
  DRIVERS: {
    name: "drivers",
    title: "Drivers",
    path: "/drivers",
    icon: "mdi mdi-account-star-variant",
  },
  DRIVERS_DETAIL: {
    name: "Driver",
    title: "Driver Details",
    path: "/drivers/:id",
    icon: "mdi mdi-account",
  },
  RIDES: {
    name: "rides",
    title: "Rides",
    path: "/rides",
    icon: "mdi mdi-car",
  },
  CONFIGURATIONS: {
    name: "configurations",
    title: "Configurations",
    path: "/configurations",
    icon: "mdi mdi-car",
  },
  NOTIFICATIONS: {
    name: "notifications",
    title: "Notifications",
    path: "/notifications",
    icon: "mdi mdi-message-text",
  },
  ADS: {
    name: "ads",
    title: "Ad Spaces",
    path: "/ads",
    icon: "mdi mdi-television",
  },
};

export default ROUTES;
