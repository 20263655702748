import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import { Link } from "react-router-dom";
import Highlight from "react-highlight";

class Ui_highlight extends Component {
  render() {
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <div className="card m-b-20">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">HTML Highlight</h4>
                    <p className="text-muted m-b-30 font-14">
                      Prism is a lightweight, extensible syntax highlighter,
                      built with modern web standards in mind.
                    </p>

                    <Highlight className="language-name-of-snippet">
                      {"<html>"}
                      {"  <!-- this is a comment -->"}
                      <br />
                      {"<head>"}
                      <br />
                      {"   <title>HTML Example</title>"}
                      <br />
                      {"</head>"}
                      <br />

                      {"<body>"}
                      <br />
                      {
                        "    The indentation tries to be <em>somewhat &quot;do what"
                      }
                      <br />
                      {
                        "    I mean&quot;</em>... but might not match your style."
                      }
                      <br />
                      {"</body>"}
                      <br />
                      {"</html>"}
                      <br />
                    </Highlight>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card m-b-20">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Css Highlight</h4>
                    <p className="text-muted m-b-30 font-14">
                      Prism is a lightweight, extensible syntax highlighter,
                      built with modern web standards in mind.
                    </p>

                    <Highlight className="language-name-of-snippet">
                      {".example {"}
                      <br />
                      {"    font-family: Georgia, Times, serif;"}
                      <br />
                      {"    color: #555;"}
                      <br />
                      {"    text-align: center;"}
                      <br />
                      {"}"}
                      <br />
                    </Highlight>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default Ui_highlight;
