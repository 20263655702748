import { toast } from "react-toastify";
import DataService from "../../services/data.service";
import { removeAuthToken, removeToken, setAuthToken, setToken } from "../../utils/auth.utils";
import { throwError, to } from "../../utils/utils";
import ACTIONS from "./types.actions";

export function userRequest() {
  return { type: ACTIONS.USER_REQUEST };
}

export function userSuccess(response) {
  return { type: ACTIONS.USER_SUCCESS, response };
}

export function userError(error) {
  return { type: ACTIONS.USER_ERROR, error };
}

export function login(credentials) {
  return async (dispatch) => {
    dispatch(userRequest());
    try {
      const [err, resp] = await to(DataService.login(credentials));
      if (err) {
        toast.error("Email or password is incorrect", {
          hideProgressBar: true,
          theme: "colored",
          closeButton: true,
        });
        throwError(err);
      }
      dispatch(userSuccess(resp.data.user));
      setToken(resp.data.user.id);
      setAuthToken(resp.data.user.jwt_token);
      return;
    } catch (error) {
      dispatch(userError(error));
      throwError(error);
    }
  };
}

export function logout() {
  return async (dispatch) => {
    try {
      console.log("Logout called")
      dispatch(userSuccess({}));
      dispatch(removeToken());
      dispatch(removeAuthToken());
    } catch (error) {}
  };
}
