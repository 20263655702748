import { produce } from "immer";
import ACTIONS from "../actions/types.actions";
const ride = produce((draft, action) => {
  switch (action.type) {
    case ACTIONS.RIDE_REQUEST:
      draft.loading = true;
      return draft;
    case ACTIONS.RIDE_SUCCESS:
      draft.loading = false;
      draft.data = action.response;
      return draft;
    case ACTIONS.RIDE_ERROR:
      draft.loading = false;
      draft.error = action.error;
      return draft;
    default:
      return draft;
  }
});

export default ride;
