import React, { Component, useState, useEffect } from "react";
import AUX from "../../hoc/Aux_";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getConfigurations, updateConfigurations } from "../../store/actions/config.actions";
import { isEmpty } from "lodash";
import { ToastContainer } from "react-toastify";

const ConfigPage = ({ loading, configs, error, getConfigurations, updateConfigurations }) => {
  const [general, setGeneral] = useState({
    id: "",
    maxDriverRadius: "",
    riderCancellationTime: "",
    dismissRideAfter: "",
    driverCancellationFee: ""
  });
  const [pricing, setPricing] = useState({
    id: "",
    costPerKM: "",
    costPerMinute: "",
    stripeChargesFixed: "",
    stripeChargesPercent: "",
    mediumCarChargesPercent: "",
    largeCarChargesPercent: "",
    driverChargesPercent: "",
    riderChargesPercent: ""
  });
  const [errors] = useState({});
  const [dirtyPricing, setDirtyPricing] = useState(false);
  const [dirtyGeneral, setDirtyGeneral] = useState(false);

  useEffect(() => {
    if (!isEmpty(configs) && !isEmpty(configs.oneData)) {
      let newGeneral = { ...general };
      let newPricing = { ...pricing };
      Object.keys(newGeneral).forEach(item => newGeneral[item] = configs.oneData[item]);
      Object.keys(newPricing).forEach(item => newPricing[item] = configs.oneData[item]);
      setGeneral(newGeneral);
      setPricing(newPricing);
    } else if (!isEmpty(configs)) {
      let newGeneral = { ...general };
      let newPricing = { ...pricing };
      Object.keys(newGeneral).forEach(item => newGeneral[item] = configs[item]);
      Object.keys(newPricing).forEach(item => newPricing[item] = configs[item]);
      setGeneral(newGeneral);
      setPricing(newPricing);
    }
  }, [configs, getConfigurations, loading]);

  useEffect(() => {
    getConfigurations();
  }, []);

  const handleChangeGeneral = (target) => {
    let newState = { ...general };
    newState[target.name] = target.value;
    // if (target.name === "maxDriverRadius") newState[target.name] = parseFloat(target.value) * 1000;
    setGeneral(newState);
    setDirtyGeneral(true);
  };

  const handleChangePricing = (target) => {
    let newState = { ...pricing };
    newState[target.name] = target.value;
    setPricing(newState);
    setDirtyPricing(true);
  };

  const handleSubmit = (type) => {
    if (type === "general" && dirtyGeneral) {
      let reqBody = Object.assign({}, general);
      Object.assign(reqBody, pricing);
      updateConfigurations(reqBody);
    } else if (type === "pricing" && dirtyPricing) {
      let reqBody = Object.assign({}, general);
      Object.assign(reqBody, pricing);
      updateConfigurations(reqBody);
    }
  };

  const reset = (type) => {
    if (type === "general") {
      setGeneral(configs);
    } else if (type === "pricing") {
      setPricing(configs);
    }
  };

  return (
    <AUX>
      <div>
        <ToastContainer style={{ ['--toastify-toast-min-height']: "40px" }} />
      </div>
      {loading ? (
        <div id="status">
          <div className="spinner" />
        </div>
      ) : (
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <div className="card m-b-20">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">General</h4>
                    <p className="text-muted m-b-30 font-14">Configure all the general parameters here</p>
                    <form className="" action="#">
                      <div className="form-group">
                        <label>Driver range</label>
                        <p className="text-muted font-14">
                          Maximum range for drivers to accept a ride within (in kilometers):
                        </p>
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control"
                            // value={general.maxDriverRadius / 1000}
                            value={general.maxDriverRadius}
                            name="maxDriverRadius"
                            onChange={(e) => {
                              handleChangeGeneral(e.target);
                            }}
                            placeholder="Radius in KM"
                          />
                          <span id="err">{errors.maxDriverRadius}</span>
                          <div className="input-group-append">
                            <span className="input-group-text">KM</span>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="form-group">
                        <label>Cancellation time limit (for rider)</label>
                        <p className="text-muted font-14">
                          Time limit within a rider can cancel the ride (in minutes):
                        </p>
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control"
                            value={general.riderCancellationTime}
                            name="riderCancellationTime"
                            onChange={(e) => {
                              handleChangeGeneral(e.target);
                            }}
                            placeholder="No of minutes"
                          />
                          <span id="err">{errors.riderCancellationTime}</span>
                          <div className="input-group-append">
                            <span className="input-group-text">Minutes</span>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="form-group">
                        <label>Driver search time limit</label>
                        <p className="text-muted font-14">
                          Maximum time to search a ride, if no driver found or accepted ride within this time limit,
                          ride will automatically be dismissed (in seconds):
                        </p>
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control"
                            value={general.dismissRideAfter}
                            name="dismissRideAfter"
                            onChange={(e) => {
                              handleChangeGeneral(e.target);
                            }}
                            placeholder="No of seconds"
                          />
                          <span id="err">{errors.dismissRideAfter}</span>
                          <div className="input-group-append">
                            <span className="input-group-text">Seconds</span>
                          </div>
                        </div>
                      </div>
                      <br />
                      {/* Cancellation Fees */}
                      <div className="form-group">
                        <label>Cancellation Fees</label>
                        <p className="text-muted font-14">
                          Cost when a driver cancel the ride (in decimal):
                        </p>
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control"
                            value={general.driverCancellationFee}
                            name="driverCancellationFee"
                            onChange={(e) => {
                              handleChangeGeneral(e.target);
                            }}
                            placeholder="Amount in decimal"
                          />
                          <span id="err">{errors.driverCancellationFee}</span>
                          <div className="input-group-append">
                            <span className="input-group-text">NZD</span>
                          </div>
                        </div>
                      </div>
                      {/* Cancellation Fees */}
                      <br />
                      <div className="form-group">
                        <div>
                          <button
                            onClick={() => handleSubmit("general")}
                            type="button"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Update
                          </button>{" "}
                          <button
                            type="reset"
                            className="btn btn-secondary waves-effect waves-light ml-3"
                            onClick={() => reset("general")}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card m-b-20">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Pricing</h4>
                    <p className="text-muted m-b-30 font-14">Setup all the pricing parameters here</p>
                    <form className="" action="#">
                      <div className="form-group">
                        <label>Cost per kilometer</label>
                        <p className="text-muted font-14">Cost per kilometer for small car (in decimal):</p>
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control"
                            value={pricing.costPerKM}
                            name="costPerKM"
                            onChange={(e) => {
                              handleChangePricing(e.target);
                            }}
                            placeholder="Amount in decimal"
                          />
                          <span id="err">{errors.costPerKM}</span>
                          <div className="input-group-append">
                            <span className="input-group-text">NZD</span>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="form-group">
                        <label>Cost per minute</label>
                        <p className="text-muted font-14">Cost per minute for small car (in decimal):</p>
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control"
                            value={pricing.costPerMinute}
                            name="costPerMinute"
                            onChange={(e) => {
                              handleChangePricing(e.target);
                            }}
                            placeholder="Amount in decimal"
                          />
                          <span id="err">{errors.costPerMinute}</span>
                          <div className="input-group-append">
                            <span className="input-group-text">NZD</span>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="form-group">
                        <label>Stripe fixed cost</label>
                        <p className="text-muted font-14">Fixed cost by Stripe per transaction (in decimal):</p>
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control"
                            value={pricing.stripeChargesFixed}
                            name="stripeChargesFixed"
                            onChange={(e) => {
                              handleChangePricing(e.target);
                            }}
                            placeholder="Amount in decimal"
                          />
                          <span id="err">{errors.stripeChargesFixed}</span>
                          <div className="input-group-append">
                            <span className="input-group-text">NZD</span>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="form-group">
                        <label>Stripe percentage</label>
                        <p className="text-muted font-14">Percentage by Stripe per transaction (in percent):</p>
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control"
                            value={pricing.stripeChargesPercent}
                            name="stripeChargesPercent"
                            onChange={(e) => {
                              handleChangePricing(e.target);
                            }}
                            placeholder="Percentage in decimal"
                          />
                          <span id="err">{errors.stripeChargesPercent}</span>
                          <div className="input-group-append">
                            <span className="input-group-text">%</span>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="form-group">
                        <label>Driver costing percentage</label>
                        <p className="text-muted font-14">Percentage cut by driver per transaction (in percent):</p>
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control"
                            value={pricing.driverChargesPercent}
                            name="driverChargesPercent"
                            onChange={(e) => {
                              handleChangePricing(e.target);
                            }}
                            placeholder="Percentage in decimal"
                          />
                          <span id="err">{errors.driverChargesPercent}</span>
                          <div className="input-group-append">
                            <span className="input-group-text">%</span>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="form-group">
                        <label>Rider costing percentage</label>
                        <p className="text-muted font-14">Percentage cut by rider per transaction (in percent):</p>
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control"
                            value={pricing.riderChargesPercent}
                            name="riderChargesPercent"
                            onChange={(e) => {
                              handleChangePricing(e.target);
                            }}
                            placeholder="Percentage in decimal"
                          />
                          <span id="err">{errors.riderChargesPercent}</span>
                          <div className="input-group-append">
                            <span className="input-group-text">%</span>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="form-group">
                        <label>Medium car costing percentage</label>
                        <p className="text-muted font-14">
                          Medium car types will cost the following percent higher than small car types (in percent):
                        </p>
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control"
                            value={pricing.mediumCarChargesPercent}
                            name="mediumCarChargesPercent"
                            onChange={(e) => {
                              handleChangePricing(e.target);
                            }}
                            placeholder="Percentage in decimal"
                          />
                          <span id="err">{errors.mediumCarChargesPercent}</span>
                          <div className="input-group-append">
                            <span className="input-group-text">%</span>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="form-group">
                        <label>Large car costing percentage</label>
                        <p className="text-muted font-14">
                          Large car types will cost the following percent higher than small car types (in percent):
                        </p>
                        <div className="input-group">
                          <input
                            type="number"
                            className="form-control"
                            value={pricing.largeCarChargesPercent}
                            name="largeCarChargesPercent"
                            onChange={(e) => {
                              handleChangePricing(e.target);
                            }}
                            placeholder="Percentage in decimal"
                          />
                          <span id="err">{errors.largeCarChargesPercent}</span>
                          <div className="input-group-append">
                            <span className="input-group-text">%</span>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div className="form-group">
                        <div>
                          <button
                            onClick={() => handleSubmit("pricing")}
                            type="button"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Update
                          </button>{" "}
                          <button
                            type="reset"
                            className="btn btn-secondary waves-effect waves-light ml-3"
                            onClick={() => reset("pricing")}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </AUX>
  );
};

const mapStatetoProps = (state) => {
  return {
    loading: state.config.loading,
    configs: state.config.data,
    error: state.config.errors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getConfigurations: () => dispatch(getConfigurations()),
  updateConfigurations: (id, body) => dispatch(updateConfigurations(id, body)),
});

export default withRouter(
  connect(
    mapStatetoProps,
    mapDispatchToProps
  )(ConfigPage)
);
