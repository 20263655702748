import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import $ from "jquery";
import menu from "./../../routes/list.menu";
import { DEBUG } from "../../utils/constants";
import AUX from "../../hoc/Aux_";

class sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Tab: "index",
      SubTab: "",
      MoreTab: "",
      activeMenu: [],
    };
  }

  setActiveTab = (tab, subtab, moretab, toggleTab, e) => {
    this.setState({ Tab: tab, SubTab: subtab, MoreTab: moretab });
  };

  componentDidMount() {
    var now_route = "";
    var pageUrl = window.location.pathname.split(/[?#]/)[0];
    now_route = pageUrl.substr(1).replace(/_/g, " ");
    $("#now_routing").empty();
    if (now_route === "") {
      // now_route = "Dashboard1";
    } else {
    }
    $("#now_routing").append(now_route);
    $(".toggle-search").on("click", function() {
      var targetId = $(this).data("target");
      var $searchBar;
      if (targetId) {
        $searchBar = $(targetId);
        $searchBar.toggleClass("open");
      }
    });
    $(".button-menu-mobile").on("click", function(event) {
      event.preventDefault();
      $("body").toggleClass("enlarged");
    });
    $("li.has_sub li").on("click", function(event) {
      $("body").toggleClass("enlarged");
    });
  }
  componentDidUpdate() {
    var now_route = "";
    var pageUrl = window.location.pathname.split(/[?#]/)[0];
    now_route = pageUrl.substr(1).replace("_", " ");
    $("#now_routing").empty();
    if (now_route === "") {
      // now_route = "Dashboard1";
    } else {
    }
    $("#now_routing").append(now_route);
  }

  render() {
    return (
      <div className="left side-menu">
        <div className="topbar-left">
          <div className="">
            <Link to="/" className="logo">
              <img src={require("../../assets/images/logo-sm.png")} height="36" alt="logo" />
            </Link>
          </div>
        </div>

        <div className="sidebar-inner slimscrollleft">
          <PerfectScrollbar>
            <div id="sidebar-menu">
              <ul>
                <li className="menu-title">Admin Area</li>

                {menu &&
                  menu.map((menuItem, index) => {
                    return (
                      <li
                        key={`k-${index}`}
                        className={
                          this.state.activeMenu[0] === menuItem.name
                            ? "has_sub active-menu nav-active"
                            : "has_sub"
                        }
                      >
                        <Link
                          to={menuItem.path}
                          onClick={this.setActiveTab.bind(
                            this,
                            menuItem.name,
                            "",
                            ""
                          )}
                          className={
                            this.state.Tab === menuItem.name
                              ? "waves-effect active-menu"
                              : "waves-effect"
                          }
                        >
                          <i className={menuItem.icon} />{" "}
                          <span>{menuItem.title}</span>{" "}
                        </Link>
                      </li>
                    );
                  })}

                {/*--------------------- TEMPLATE PART STARTED -------------------*/}
                {DEBUG && (
                  <AUX>
                    <li className="menu-title">Main</li>
                    <li
                      className={
                        this.state.activeMenu[0] === "dashboard_menu"
                          ? "has_sub active-menu nav-active"
                          : "has_sub"
                      }
                    >
                      <Link
                        to="#"
                        onClick={() =>
                          this.setState({
                            activeMenu: ["dashboard_menu"],
                          })
                        }
                        className={
                          this.state.Tab === "index"
                            ? "waves-effect active-menu"
                            : "waves-effect"
                        }
                      >
                        <i className="mdi mdi-view-dashboard" />{" "}
                        <span>
                          {" "}
                          Dashboard{" "}
                          <span className="badge badge-pill badge-primary pull-right">
                            20+
                          </span>
                        </span>{" "}
                      </Link>
                      <ul
                        className="list-unstyled"
                        style={{
                          display:
                            this.state.activeMenu[0] === "dashboard_menu"
                              ? "block"
                              : "none",
                        }}
                      >
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "dashboard1"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "index",
                              "dashboard1",
                              ""
                            )}
                            to="/"
                          >
                            Dashboard One
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "dashboard2"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "index",
                              "dashboard2",
                              ""
                            )}
                            to="dashboard2"
                          >
                            Dashboard Two
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link
                        to="widgets"
                        className={
                          this.state.Tab === "widget"
                            ? "waves-effect active-menu"
                            : "waves-effect"
                        }
                        onClick={this.setActiveTab.bind(this, "widget", "", "")}
                      >
                        <i className="mdi mdi-cube-outline" />
                        <span> Widgets </span>
                      </Link>
                    </li>
                    <li
                      className={
                        this.state.activeMenu[0] === "email_menu"
                          ? "has_sub active-menu nav-active"
                          : "has_sub"
                      }
                    >
                      <Link
                        to="#"
                        onClick={() =>
                          this.setState({
                            activeMenu: ["email_menu"],
                          })
                        }
                        className={
                          this.state.Tab === "email"
                            ? "waves-effect active-menu"
                            : "waves-effect"
                        }
                      >
                        <i className="mdi mdi-email-outline" />
                        <span>
                          {" "}
                          Email{" "}
                          <span className="pull-right">
                            <i className="mdi mdi-chevron-right" />
                          </span>{" "}
                        </span>
                      </Link>
                      <ul
                        className="list-unstyled"
                        style={{
                          display:
                            this.state.activeMenu[0] === "email_menu"
                              ? "block"
                              : "none",
                        }}
                      >
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "email_inbox"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "email",
                              "email_inbox",
                              ""
                            )}
                            to="email_inbox"
                          >
                            Inbox
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "email_read"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "email",
                              "email_read",
                              ""
                            )}
                            to="email_read"
                          >
                            Email Read
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "email_compose"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "email",
                              "email_compose",
                              ""
                            )}
                            to="email_compose"
                          >
                            Email Compose
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link
                        to="calendar"
                        className={
                          this.state.Tab === "calendar"
                            ? "waves-effect active-menu"
                            : "waves-effect"
                        }
                        onClick={this.setActiveTab.bind(
                          this,
                          "calendar",
                          "",
                          ""
                        )}
                      >
                        <i className="mdi mdi-calendar-check" />
                        <span> Calendar </span>
                      </Link>
                    </li>
                    <li className="menu-title">Components</li>
                    <li
                      className={
                        this.state.activeMenu[0] === "ui_menu"
                          ? "has_sub active-menu nav-active"
                          : "has_sub"
                      }
                    >
                      <Link
                        to="#"
                        onClick={() =>
                          this.setState({
                            activeMenu: ["ui_menu"],
                          })
                        }
                        className={
                          this.state.Tab === "ui"
                            ? "waves-effect active-menu"
                            : "waves-effect"
                        }
                      >
                        <i className="mdi mdi-buffer" />{" "}
                        <span>
                          {" "}
                          User Interface{" "}
                          <span className="pull-right">
                            <i className="mdi mdi-chevron-right" />
                          </span>{" "}
                        </span>{" "}
                      </Link>
                      <ul
                        className="list-unstyled"
                        style={{
                          display:
                            this.state.activeMenu[0] === "ui_menu"
                              ? "block"
                              : "none",
                        }}
                      >
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "buttons"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "buttons",
                              ""
                            )}
                            to="buttons"
                          >
                            Buttons
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "colors"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "colors",
                              ""
                            )}
                            to="colors"
                          >
                            Colors
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "cards" ? "active-menu" : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "cards",
                              ""
                            )}
                            to="cards"
                          >
                            Cards
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "tabs_accordions"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "tabs_accordions",
                              ""
                            )}
                            to="tabs_accordions"
                          >
                            Tabs &amp; Accordions
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "modals"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "modals",
                              ""
                            )}
                            to="modals"
                          >
                            Modals
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "images"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "images",
                              ""
                            )}
                            to="images"
                          >
                            Images
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "alerts"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "alerts",
                              ""
                            )}
                            to="alerts"
                          >
                            Alerts
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "progressbars"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "progressbars",
                              ""
                            )}
                            to="progressbars"
                          >
                            Progress Bars
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "dropdowns"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "dropdowns",
                              ""
                            )}
                            to="dropdowns"
                          >
                            Dropdowns
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "lightbox"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "lightbox",
                              ""
                            )}
                            to="lightbox"
                          >
                            Lightbox
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "navs" ? "active-menu" : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "navs",
                              ""
                            )}
                            to="navs"
                          >
                            Navs
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "pagination"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "pagination",
                              ""
                            )}
                            to="pagination"
                          >
                            Pagination
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "popover_tooltips"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "popover_tooltips",
                              ""
                            )}
                            to="popover_tooltips"
                          >
                            Popover & Tooltips
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "badge" ? "active-menu" : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "badge",
                              ""
                            )}
                            to="badge"
                          >
                            Badge
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "carousel"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "carousel",
                              ""
                            )}
                            to="carousel"
                          >
                            Carousel
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "video" ? "active-menu" : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "video",
                              ""
                            )}
                            to="video"
                          >
                            Video
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "typography"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "typography",
                              ""
                            )}
                            to="typography"
                          >
                            Typography
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "sweet_alert"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "sweet_alert",
                              ""
                            )}
                            to="sweet_alert"
                          >
                            Sweet-Alert
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "grid" ? "active-menu" : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "grid",
                              ""
                            )}
                            to="grid"
                          >
                            Grid
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "animation"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "animation",
                              ""
                            )}
                            to="animation"
                          >
                            Animation
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "highlight"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "highlight",
                              ""
                            )}
                            to="highlight"
                          >
                            Highlight
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "rating"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "rating",
                              ""
                            )}
                            to="rating"
                          >
                            Rating
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "nestable"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "nestable",
                              ""
                            )}
                            to="nestable"
                          >
                            Nestable
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "alertify"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "alertify",
                              ""
                            )}
                            to="alertify"
                          >
                            Alertify
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "rangeslider"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "rangeslider",
                              ""
                            )}
                            to="rangeslider"
                          >
                            Range Slider
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "sessiontimeout"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "ui",
                              "sessiontimeout",
                              ""
                            )}
                            to="sessiontimeout"
                          >
                            Session Timeout
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      className={
                        this.state.activeMenu[0] === "form_menu"
                          ? "has_sub active-menu nav-active"
                          : "has_sub"
                      }
                    >
                      <Link
                        to="#"
                        onClick={() =>
                          this.setState({
                            activeMenu: ["form_menu"],
                          })
                        }
                        className={
                          this.state.Tab === "form"
                            ? "waves-effect active"
                            : "waves-effect"
                        }
                      >
                        <i className="mdi mdi-clipboard-outline" />
                        <span>
                          {" "}
                          Forms{" "}
                          <span className="badge badge-pill badge-success pull-right">
                            9
                          </span>{" "}
                        </span>
                      </Link>
                      <ul
                        className="list-unstyled"
                        style={{
                          display:
                            this.state.activeMenu[0] === "form_menu"
                              ? "block"
                              : "none",
                        }}
                      >
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "form_elements"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "form",
                              "form_elements",
                              ""
                            )}
                            to="form_elements"
                          >
                            Form Elements
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "form_validation"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "form",
                              "form_validation",
                              ""
                            )}
                            to="form_validation"
                          >
                            Form Validation
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "form_advanced"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "form",
                              "form_advanced",
                              ""
                            )}
                            to="form_advanced"
                          >
                            Form Advanced
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "form_wizard"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "form",
                              "form_wizard",
                              ""
                            )}
                            to="form_wizard"
                          >
                            Form Wizard
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "form_editors"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "form",
                              "form_editors",
                              ""
                            )}
                            to="form_editors"
                          >
                            Form Editors
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "form_uploads"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "form",
                              "form_uploads",
                              ""
                            )}
                            to="form_uploads"
                          >
                            Form File Upload
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "form_mask"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "form",
                              "form_mask",
                              ""
                            )}
                            to="form_mask"
                          >
                            Form Mask
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "form_summernote"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "form",
                              "form_summernote",
                              ""
                            )}
                            to="form_summernote"
                          >
                            Summernote
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "form_xeditable"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "form",
                              "form_xeditable",
                              ""
                            )}
                            to="form_xeditable"
                          >
                            Form Xeditable
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      className={
                        this.state.activeMenu[0] === "chart_menu"
                          ? "has_sub active-menu nav-active"
                          : "has_sub"
                      }
                    >
                      <Link
                        to="#"
                        onClick={() =>
                          this.setState({
                            activeMenu: ["chart_menu"],
                          })
                        }
                        className={
                          this.state.Tab === "chart"
                            ? "waves-effect active"
                            : "waves-effect"
                        }
                      >
                        <i className="mdi mdi-chart-line" />
                        <span>
                          {" "}
                          Charts{" "}
                          <span className="pull-right">
                            <i className="mdi mdi-chevron-right" />
                          </span>{" "}
                        </span>
                      </Link>
                      <ul
                        className="list-unstyled"
                        style={{
                          display:
                            this.state.activeMenu[0] === "chart_menu"
                              ? "block"
                              : "none",
                        }}
                      >
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "charts_easy"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "chart",
                              "charts_easy",
                              ""
                            )}
                            to="charts_easy"
                          >
                            Easy Chart
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "chartjs"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "chart",
                              "chartjs",
                              ""
                            )}
                            to="chartjs"
                          >
                            ChartJs Chart
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "apexchart"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "chart",
                              "apexchart",
                              ""
                            )}
                            to="apexchart"
                          >
                            Apex Chart
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "sparkline"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "chart",
                              "sparkline",
                              ""
                            )}
                            to="sparkline"
                          >
                            SparkLine Chart
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      className={
                        this.state.activeMenu[0] === "table_menu"
                          ? "has_sub active-menu nav-active"
                          : "has_sub"
                      }
                    >
                      <Link
                        to="#"
                        onClick={() =>
                          this.setState({
                            activeMenu: ["table_menu"],
                          })
                        }
                        className={
                          this.state.Tab === "table"
                            ? "waves-effect active-menu"
                            : "waves-effect"
                        }
                      >
                        <i className="mdi mdi-format-list-bulleted-type" />
                        <span>
                          {" "}
                          Tables{" "}
                          <span className="pull-right">
                            <i className="mdi mdi-chevron-right" />
                          </span>{" "}
                        </span>
                      </Link>
                      <ul
                        className="list-unstyled"
                        style={{
                          display:
                            this.state.activeMenu[0] === "table_menu"
                              ? "block"
                              : "none",
                        }}
                      >
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "tables_basic"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "table",
                              "tables_basic",
                              ""
                            )}
                            to="tables_basic"
                          >
                            Basic Tables
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "tables_datatable"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "table",
                              "tables_datatable",
                              ""
                            )}
                            to="tables_datatable"
                          >
                            Data Table
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "tables_responsive"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "table",
                              "tables_responsive",
                              ""
                            )}
                            to="tables_responsive"
                          >
                            Responsive Table
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "tables_editable"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "table",
                              "tables_editable",
                              ""
                            )}
                            to="tables_editable"
                          >
                            Editable Table
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      className={
                        this.state.activeMenu[0] === "icon_menu"
                          ? "has_sub active-menu nav-active"
                          : "has_sub"
                      }
                    >
                      <Link
                        to="#"
                        onClick={() =>
                          this.setState({
                            activeMenu: ["icon_menu"],
                          })
                        }
                        className={
                          this.state.Tab === "icon"
                            ? "waves-effect active-menu"
                            : "waves-effect"
                        }
                      >
                        <i className="mdi mdi-album" />{" "}
                        <span>
                          {" "}
                          Icons{" "}
                          <span className="pull-right">
                            <i className="mdi mdi-chevron-right" />
                          </span>
                        </span>{" "}
                      </Link>
                      <ul
                        className="list-unstyled"
                        style={{
                          display:
                            this.state.activeMenu[0] === "icon_menu"
                              ? "block"
                              : "none",
                        }}
                      >
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "icons_material"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "icon",
                              "icons_material",
                              ""
                            )}
                            to="icons_material"
                          >
                            Material Design
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "icons_ion"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "icon",
                              "icons_ion",
                              ""
                            )}
                            to="icons_ion"
                          >
                            Ion Icons
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "icons_fontawesome"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "icon",
                              "icons_fontawesome",
                              ""
                            )}
                            to="icons_fontawesome"
                          >
                            Font Awesome
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "icons_themify"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "icon",
                              "icons_themify",
                              ""
                            )}
                            to="icons_themify"
                          >
                            Themify Icons
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "icons_dripicons"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "icon",
                              "icons_dripicons",
                              ""
                            )}
                            to="icons_dripicons"
                          >
                            Dripicons
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "icons_typicons"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "icon",
                              "icons_typicons",
                              ""
                            )}
                            to="icons_typicons"
                          >
                            Typicons I_ons
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "icons_weather"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "icon",
                              "icons_weather",
                              ""
                            )}
                            to="icons_weather"
                          >
                            Weather Icons
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "icons_mobirise"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "icon",
                              "icons_mobirise",
                              ""
                            )}
                            to="icons_mobirise"
                          >
                            Mobirise Icons
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      className={
                        this.state.activeMenu[0] === "map_menu"
                          ? "has_sub active-menu nav-active"
                          : "has_sub"
                      }
                    >
                      <Link
                        to="#"
                        onClick={() =>
                          this.setState({
                            activeMenu: ["map_menu"],
                          })
                        }
                        className={
                          this.state.Tab === "map"
                            ? "waves-effect active-menu"
                            : "waves-effect"
                        }
                      >
                        <i className="mdi mdi-google-maps" />
                        <span>
                          {" "}
                          Maps{" "}
                          <span className="badge badge-pill badge-danger pull-right">
                            2
                          </span>{" "}
                        </span>
                      </Link>
                      <ul
                        className="list-unstyled"
                        style={{
                          display:
                            this.state.activeMenu[0] === "map_menu"
                              ? "block"
                              : "none",
                        }}
                      >
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "maps_google"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "map",
                              "maps_google",
                              ""
                            )}
                            to="maps_google"
                          >
                            {" "}
                            Google Map
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "maps_vector"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "map",
                              "maps_vector",
                              ""
                            )}
                            to="maps_vector"
                          >
                            {" "}
                            Vector Map
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-title">Extras</li>
                    <li
                      className={
                        this.state.activeMenu[0] === "auth_menu"
                          ? "has_sub active-menu nav-active"
                          : "has_sub"
                      }
                    >
                      <Link
                        to="#"
                        onClick={() =>
                          this.setState({
                            activeMenu: ["auth_menu"],
                          })
                        }
                        className="waves-effect"
                      >
                        <i className="mdi mdi-account-location" />
                        <span>
                          {" "}
                          Authentication{" "}
                          <span className="pull-right">
                            <i className="mdi mdi-chevron-right" />
                          </span>{" "}
                        </span>
                      </Link>
                      <ul
                        className="list-unstyled"
                        style={{
                          display:
                            this.state.activeMenu[0] === "auth_menu"
                              ? "block"
                              : "none",
                        }}
                      >
                        <li>
                          <Link to="pages_login">Login</Link>
                        </li>
                        <li>
                          <Link to="pages_register">Register</Link>
                        </li>
                        <li>
                          <Link to="pages_recoverpw">Recover Password</Link>
                        </li>
                        <li>
                          <Link to="pages_lock_screen">Lock Screen</Link>
                        </li>
                        <li>
                          <Link to="pages_login_2">Login 2</Link>
                        </li>
                        <li>
                          <Link to="pages_register_2">Register 2</Link>
                        </li>
                        <li>
                          <Link to="pages_recoverpw_2">Recover Password 2</Link>
                        </li>
                        <li>
                          <Link to="pages_lock_screen_2">Lock Screen 2</Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      className={
                        this.state.activeMenu[0] === "extra_menu"
                          ? "has_sub active-menu nav-active"
                          : "has_sub"
                      }
                    >
                      <Link
                        to="#"
                        onClick={() =>
                          this.setState({
                            activeMenu: ["extra_menu"],
                          })
                        }
                        className={
                          this.state.Tab === "extra"
                            ? "waves-effect active-menu"
                            : "waves-effect"
                        }
                      >
                        <i className="mdi mdi-google-pages" />
                        <span>
                          {" "}
                          Extra Pages{" "}
                          <span className="pull-right">
                            <i className="mdi mdi-chevron-right" />
                          </span>{" "}
                        </span>
                      </Link>
                      <ul
                        className="list-unstyled"
                        style={{
                          display:
                            this.state.activeMenu[0] === "extra_menu"
                              ? "block"
                              : "none",
                        }}
                      >
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "pages_timeline"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "extra",
                              "pages_timeline",
                              ""
                            )}
                            to="pages_timeline"
                          >
                            Timeline
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "pages_invoice"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "extra",
                              "pages_invoice",
                              ""
                            )}
                            to="pages_invoice"
                          >
                            Invoice
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "pages_directory"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "extra",
                              "pages_directory",
                              ""
                            )}
                            to="pages_directory"
                          >
                            Directory
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "pages_blank"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "extra",
                              "pages_blank",
                              ""
                            )}
                            to="pages_blank"
                          >
                            Blank Page
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "pages_400"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "extra",
                              "pages_400",
                              ""
                            )}
                            to="pages_400"
                          >
                            Error 404
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "pages_500"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "extra",
                              "pages_500",
                              ""
                            )}
                            to="pages_500"
                          >
                            Error 500
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "pages_pricing"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "extra",
                              "pages_pricing",
                              ""
                            )}
                            to="pages_pricing"
                          >
                            Pricing
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "pages_gallery"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "extra",
                              "pages_gallery",
                              ""
                            )}
                            to="pages_gallery"
                          >
                            Gallery
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "pages_maintenance"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "extra",
                              "pages_maintenance",
                              ""
                            )}
                            to="pages_maintenance"
                          >
                            Maintenance
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "pages_coming_soon"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "extra",
                              "pages_coming_soon",
                              ""
                            )}
                            to="pages_coming_soon"
                          >
                            Coming Soon
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li
                      className={
                        this.state.activeMenu[0] === "eco_menu"
                          ? "has_sub active-menu nav-active"
                          : "has_sub"
                      }
                    >
                      <Link
                        to="#"
                        onClick={() =>
                          this.setState({
                            activeMenu: ["eco_menu"],
                          })
                        }
                        className={
                          this.state.Tab === "eco"
                            ? "waves-effect active-menu"
                            : "waves-effect"
                        }
                      >
                        <i className="mdi mdi-cart-outline" />
                        <span>
                          {" "}
                          Ecommerce{" "}
                          <span className="pull-right">
                            <i className="mdi mdi-chevron-right" />
                          </span>{" "}
                        </span>
                      </Link>
                      <ul
                        className="list-unstyled"
                        style={{
                          display:
                            this.state.activeMenu[0] === "eco_menu"
                              ? "block"
                              : "none",
                        }}
                      >
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "ecommerce_product_list"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "eco",
                              "ecommerce_product_list",
                              ""
                            )}
                            to="ecommerce_product_list"
                          >
                            Product List
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "ecommerce_product_grid"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "eco",
                              "ecommerce_product_grid",
                              ""
                            )}
                            to="ecommerce_product_grid"
                          >
                            Product Grid
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "ecommerce_order_history"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "eco",
                              "ecommerce_order_history",
                              ""
                            )}
                            to="ecommerce_order_history"
                          >
                            Order History
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "ecommerce_customers"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "eco",
                              "ecommerce_customers",
                              ""
                            )}
                            to="ecommerce_customers"
                          >
                            Customers
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "ecommerce_product_edit"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "eco",
                              "ecommerce_product_edit",
                              ""
                            )}
                            to="ecommerce_product_edit"
                          >
                            Product Edit
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="/" className="waves-effect" target="_blank">
                        <i className="mdi mdi-airplane" />
                        <span> Front End </span>
                      </a>
                    </li>
                    <li
                      className={
                        this.state.activeMenu[0] === "emt_menu"
                          ? "has_sub active-menu nav-active"
                          : "has_sub"
                      }
                    >
                      <Link
                        to="#"
                        onClick={() =>
                          this.setState({
                            activeMenu: ["emt_menu"],
                          })
                        }
                        className={
                          this.state.Tab === "emt"
                            ? "waves-effect active-menu"
                            : "waves-effect"
                        }
                      >
                        <i className="mdi mdi-flask-outline" />
                        <span>
                          {" "}
                          Email Templates{" "}
                          <span className="pull-right">
                            <i className="mdi mdi-chevron-right" />
                          </span>{" "}
                        </span>
                      </Link>
                      <ul
                        className="list-unstyled"
                        style={{
                          display:
                            this.state.activeMenu[0] === "emt_menu"
                              ? "block"
                              : "none",
                        }}
                      >
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "email_templates_basic"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "emt",
                              "email_templates_basic",
                              ""
                            )}
                            to="email_templates_basic"
                          >
                            Basic Action Email
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "email_templates_alert"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "emt",
                              "email_templates_alert",
                              ""
                            )}
                            to="email_templates_alert"
                          >
                            Alert Email
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={
                              this.state.SubTab === "email_templates_billing"
                                ? "active-menu"
                                : ""
                            }
                            onClick={this.setActiveTab.bind(
                              this,
                              "emt",
                              "email_templates_billing",
                              ""
                            )}
                            to="email_templates_billing"
                          >
                            Billing Email
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-title">Help & Support</li>
                    <li>
                      <Link
                        to="pages_faq"
                        className={
                          this.state.Tab === "faq"
                            ? "waves-effect active-menu"
                            : "waves-effect"
                        }
                        onClick={this.setActiveTab.bind(this, "faq", "", "")}
                      >
                        <i className="mdi mdi-help-circle" />
                        <span> FAQ </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="pages_contact"
                        className={
                          this.state.Tab === "contact"
                            ? "waves-effect active-menu"
                            : "waves-effect"
                        }
                        onClick={this.setActiveTab.bind(
                          this,
                          "contact",
                          "",
                          ""
                        )}
                      >
                        <i className="mdi mdi-headset" />
                        <span>
                          {" "}
                          Contact{" "}
                          <span className="badge badge-pill badge-warning pull-right">
                            3
                          </span>{" "}
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="waves-effect">
                        <i className="mdi mdi-file-document-box" />
                        <span> Documentation </span>
                      </Link>
                    </li>
                  </AUX>
                )}
              </ul>
            </div>

            <div className="clearfix" />
          </PerfectScrollbar>
        </div>
      </div>
    );
  }
}

export default withRouter(sidebar);
