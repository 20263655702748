import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import AUX from "../../hoc/Aux_";
import $ from "jquery";
import { getCustomers, getCustomerById, updateCustomer, sendVerificationMail } from "../../store/actions/customer.actions";
import { ToastContainer, toast } from "react-toastify";

const CustomersDetail = (props) => {
  const { loading, getCustomerById, error, customerDetail, updateCustomer, sendVerificationMail } = props;

  const [customer, setCustomer] = useState(null);
  const [updatedData, setUpdatedData] = useState(null);
  const [editable, setEditable] = useState(true);
  const [dirty, setDirty] = useState(false);

  let { id } = useParams();

  useEffect(() => {
    $("#now_routing")
      .empty()
      .append("Customer Details");

    if (props.location.data) {
      console.log(">>>", props.location.data)
      setCustomer(props.location.data);
    } else {
      getCustomerById(id);
    }
  }, []);

  useEffect(() => {
    if (customerDetail && !customerDetail.success && !props.location.data) {
      setCustomer(customerDetail);
    }
  }, [customerDetail]);

  const handleChange = (target) => {
    let newState = { ...customer };
    let updated = { ...updatedData };
    newState[target.name] = target.value;
    updated[target.name] = target.value;

    setCustomer(newState);
    setUpdatedData(updated);
    setDirty(true);
  };

  const onSubmit = () => {
    console.log(updatedData, Object.values({ ...updatedData }).length, Object.values({ ...updatedData }).every(item => item))
    if (dirty) {
      if (Object.values({ ...updatedData }).length > 0 && Object.values({ ...updatedData }).every(item => item)) {
        // updateCustomer(Object.assign({ id: customer.id }, { ...updatedData }));
        if({ ...updatedData } && ({ ... updatedData }.emergencyContact === customer.phone)) {
          toast.error("Emergency contact and phone number cannot be same", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        } else {
          updateCustomer(Object.assign({ id: customer.id }, { ...updatedData }));
        }
      } else {
        toast.error("Please fill up all the fields", {
          hideProgressBar: true,
          theme: "colored",
          closeButton: true,
        });
      }
    }
    // if (dirty) {
    //   updateCustomer(Object.assign({ id: customer.id }, { ...updatedData }));
    // }
  };

  const resendMail = (body) => {
    console.log("Email sent", body);
    sendVerificationMail(body);
  }

  return (
    <AUX>
      <div>
        <ToastContainer style={{ ['--toastify-toast-min-height']: "40px" }} />
      </div>
      {loading || !customer ? (
        <div id="status">
          <div className="spinner" />
        </div>
      ) : (
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  {customer ? (
                    <div className="card-body">
                      <div className="form-group row">
                        <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                          Customer ID
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Customer ID"
                            id="example-text-input"
                            value={customer.id}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                          First Name
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="First Name"
                            id="example-text-input"
                            name={"firstName"}
                            value={customer.firstName}
                            disabled={!editable}
                            onBlur={(e) => {
                              e.target.value = e.target.value.trim();
                              handleChange(e.target);
                            }}
                            onChange={(e) => {
                              e.target.value = e.target.value.replace(/[^a-zA-Z\s]+/g, '')
                              handleChange(e.target);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                          Last Name
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Last Name"
                            id="example-text-input"
                            name={"lastName"}
                            value={customer.lastName}
                            disabled={!editable}
                            onBlur={(e) => {
                              e.target.value = e.target.value.trim();
                              handleChange(e.target);
                            }}
                            onChange={(e) => {
                              e.target.value = e.target.value.replace(/[^a-zA-Z\s]+/g, '')
                              handleChange(e.target);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                          Email
                        </label>
                        <div className="col-sm-10">
                          <div className="input-group">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Email"
                              id="example-text-input"
                              value={customer.email}
                              disabled={true}
                            />
                            <div className="input-group-append" style={{ display: (customer.isEmailVerified === 1) ? "none" : "block" }}>
                              <button onClick={() =>
                                resendMail({ id: customer.id })
                              }
                                className="btn btn-primary waves-effect waves-light" type="button">Resend Mail</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                          Phone
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Phone No"
                            id="example-text-input"
                            value={customer.phone}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                          Emergency Contact
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="N/A"
                            id="example-text-input"
                            maxLength={10}
                            value={customer.emergencyContact}
                            disabled={!editable}
                            name="emergencyContact"
                            onChange={(e) => {
                              e.target.value = e.target.value.replace(/[^0-9]+/g, '')
                              handleChange(e.target);
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="form-group row">
                        <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                          Username
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Username"
                            id="example-text-input"
                            value={customer.phone}
                            disabled={true}
                          />
                        </div>
                      </div> */}
                      <div className="form-group row">
                        <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                          Stripe Account ID
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Stripe Account ID"
                            id="example-text-input"
                            value={customer.stripeCustomerId}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="example-datetime-local-input" className="col-sm-2 col-form-label">
                          Created At
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="datetime-local"
                            value={moment(customer.createdAt).format("YYYY-MM-DDTHH:mm:ss")}
                            id="example-datetime-local-input"
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label htmlFor="example-datetime-local-input" className="col-sm-2 col-form-label">
                          Last Updated At
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="datetime-local"
                            value={moment(customer.updatedAt).format("YYYY-MM-DDTHH:mm:ss")}
                            id="example-datetime-local-input"
                            disabled={true}
                          />
                        </div>
                      </div>
                      <br />
                      <div>
                        <div>
                          <button onClick={onSubmit} type="button" className="btn btn-primary waves-effect waves-light">
                            Update
                          </button>{" "}
                          <button
                            type="reset"
                            className="btn btn-secondary waves-effect ml-3"
                            onClick={props.history.goBack}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </AUX>
  );
};

const mapStatetoProps = (state) => {
  return {
    loading: state.customer.loading,
    customerDetail: state.customer.detail,
    error: state.customer.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCustomers: () => dispatch(getCustomers()),
  getCustomerById: (id) => dispatch(getCustomerById(id)),
  updateCustomer: (id, body) => dispatch(updateCustomer(id, body)),
  sendVerificationMail: (body) => dispatch(sendVerificationMail(body))
});

export default withRouter(
  connect(
    mapStatetoProps,
    mapDispatchToProps
  )(CustomersDetail)
);
