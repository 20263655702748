import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "../node_modules/fullcalendar/dist/fullcalendar.min.js";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/configureStore";
import 'react-toastify/dist/ReactToastify.css';

const storeConfig = configureStore();

const app = (
  <storeConfig.Provider store={storeConfig.store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </storeConfig.Provider>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
