import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";

class Pages_blank extends Component {
  render() {
    return (
      <AUX>
        <div class="page-content-wrapper">
          <div class="container-fluid" />
        </div>
      </AUX>
    );
  }
}

export default Pages_blank;
