import { toast } from "react-toastify";
import DataService from "../../services/data.service";
import { throwError, to } from "../../utils/utils";
import ACTIONS from "./types.actions";

export function rideRequest() {
  return { type: ACTIONS.RIDE_REQUEST };
}

export function rideSuccess(response) {
  return { type: ACTIONS.RIDE_SUCCESS, response };
}

export function rideError(error) {
  return { type: ACTIONS.RIDE_ERROR, error };
}

export function getRides() {
  return async (dispatch) => {
    dispatch(rideRequest());
    try {
      const [err, resp] = await to(DataService.getRides());
      if (err) {
        const {status} = err.response;
        if(status === DataService.UNAUTHORIZED) {
          toast.error("Unauthorized or session timed out. Kindly relogin", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        } else {
          toast.error("Something went wrong", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        }
        throwError(err);
      }
      dispatch(rideSuccess(resp.data.rides.rows));
      return;
    } catch (error) {
      dispatch(rideError(error));
      throwError(error);
    }
  };
}
