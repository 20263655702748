// export const BASE_URL = "http://157.230.221.95:3010/api/";
// export const BASE_URL_IMAGES = "http://157.230.221.95:3010/api/Attachments/images/download/";

// Production URL
export const BASE_URL = "https://api.waka-rideshare.appstack.me/api/";
// export const BASE_URL = "https://api.wakarideshare.co.nz/api/";
// export const BASE_URL_IMAGES = "https://api.waka-rideshare.com:3010/api/Attachments/images/download/";

// Development URL
// export const BASE_URL = "https://wakaapi.anavadya.tech/api/";
// export const BASE_URL_IMAGES = "https://wakaapi.anavadya.tech/api/Attachments/images/download/";

export const TOKEN = "CT_TOKEN";
export const AUTH_TOKEN = "AUTH_TOKEN";
export const DEBUG = false