import dashboard1 from "../containers/MainContent/Dashboard/Dashboard1";
import AdsPage from "../containers/pages/Ads.page";
import ConfigPage from "../containers/pages/Config.page";
import CustomersList from "../containers/pages/Customers.page";
import CustomersDetail from "../containers/pages/CustomersDetail.page";
import DriversList from "../containers/pages/Drivers.page";
import DriversDetail from "../containers/pages/DriversDetail.page";
import LoginPage from "../containers/pages/Login.page";
import NotificationsPage from "../containers/pages/Notifications.page";
import RidesList from "../containers/pages/Rides.page";
import ROUTES from "./constant.route";

const routes = [
  {
    path: `${ROUTES.LOGIN.path}`,
    exact: true,
    name: ROUTES.LOGIN.name,
    displayName: ROUTES.LOGIN.title,
    component: LoginPage,
    isPrivate: false,
  },
  {
    path: `${ROUTES.CUSTOMERS.path}`,
    exact: true,
    name: ROUTES.CUSTOMERS.name,
    displayName: ROUTES.CUSTOMERS.title,
    component: CustomersList,
    isPrivate: true,
  },
  {
    path: `${ROUTES.CUSTOMERS_DETAIL.path}`,
    exact: true,
    name: ROUTES.CUSTOMERS_DETAIL.name,
    displayName: ROUTES.CUSTOMERS_DETAIL.title,
    component: CustomersDetail,
    isPrivate: true,
  },
  {
    path: `${ROUTES.DRIVERS.path}`,
    exact: true,
    name: ROUTES.DRIVERS.name,
    displayName: ROUTES.DRIVERS.title,
    component: DriversList,
    isPrivate: true,
  },
  {
    path: `${ROUTES.DRIVERS_DETAIL.path}`,
    exact: true,
    name: ROUTES.DRIVERS_DETAIL.name,
    displayName: ROUTES.DRIVERS_DETAIL.title,
    component: DriversDetail,
    isPrivate: true,
  },
  {
    path: `${ROUTES.RIDES.path}`,
    exact: true,
    name: ROUTES.RIDES.name,
    displayName: ROUTES.RIDES.title,
    component: RidesList,
    isPrivate: true,
  },
  {
    path: `${ROUTES.HOME.path}`,
    exact: true,
    name: ROUTES.HOME.name,
    displayName: ROUTES.HOME.title,
    component: dashboard1,
    isPrivate: true,
  },
  {
    path: `${ROUTES.CONFIGURATIONS.path}`,
    exact: true,
    name: ROUTES.CONFIGURATIONS.name,
    displayName: ROUTES.CONFIGURATIONS.title,
    component: ConfigPage,
    isPrivate: true,
  },
  {
    path: `${ROUTES.NOTIFICATIONS.path}`,
    exact: true,
    name: ROUTES.NOTIFICATIONS.name,
    displayName: ROUTES.NOTIFICATIONS.title,
    component: NotificationsPage,
    isPrivate: true,
  },
  {
    path: `${ROUTES.ADS.path}`,
    exact: true,
    name: ROUTES.ADS.name,
    displayName: ROUTES.ADS.title,
    component: AdsPage,
    isPrivate: true,
  },
];

export default routes;
