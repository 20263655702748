import ACTIONS from "../actions/types.actions";
import appInitialState from "../app-state/app-initial.state";
import customer from "./customer.reducer";
import driver from "./driver.reducer";
import ride from "./ride.reducer";
import user from "./user.reducer";
import config from "./config.reducer";
import ad from "./ad.reducer";

export default function reducer(state = appInitialState, action) {
  if (action.type === ACTIONS.APP_RESET) {
    return appInitialState;
  }
  return {
    user: user(state.user, action),
    customer: customer(state.customer, action),
    driver: driver(state.driver, action),
    ride: ride(state.ride, action),
    config: config(state.config, action),
    ad: ad(state.ad, action),
  };
}
