import { toast } from "react-toastify";
import DataService from "../../services/data.service";
import { throwError, to } from "../../utils/utils";
import ACTIONS from "./types.actions";

export function driverRequest() {
  return { type: ACTIONS.DRIVER_REQUEST };
}

export function driverSuccess(response) {
  return { type: ACTIONS.DRIVER_SUCCESS, response };
}

export function driverDetailSuccess(response) {
  return { type: ACTIONS.DRIVER_DETAIL_SUCCESS, response };
}

export function carTypeSuccess(response) {
  return { type: ACTIONS.CAR_TYPE_SUCCESS, response };
}

export function driverError(error) {
  return { type: ACTIONS.DRIVER_ERROR, error };
}

export function driverDocuments(documents) {
  return { type: ACTIONS.DRIVER_DOCUMENTS, documents };
}

export function getDrivers() {
  return async (dispatch) => {
    dispatch(driverRequest());
    try {
      const [err, resp] = await to(DataService.getDrivers());
      if (err) {
        const {status} = err.response;
        if(status === DataService.UNAUTHORIZED) {
          toast.error("Unauthorized or session timed out. Kindly relogin", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        } else {
          toast.error("Something went wrong", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        }
        throwError(err);
      }
      dispatch(driverSuccess(resp.data.user));
      return;
    } catch (error) {
      dispatch(driverError(error));
      throwError(error);
    }
  };
}

export function getDriverById(id) {
  return async (dispatch) => {
    dispatch(driverRequest());
    try {
      const [err, resp] = await to(DataService.getDriverById(id));
      if (err) {
        const {status} = err.response;
        if(status === DataService.UNAUTHORIZED) {
          toast.error("Unauthorized or session timed out. Kindly relogin", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        } else {
          toast.error("Something went wrong", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        }
        throwError(err);
      }
      dispatch(driverDetailSuccess(resp.data.user));
      return;
    } catch (error) {
      dispatch(driverError(error));
      throwError(error);
    }
  };
}

export function getCarTypes() {
  return async (dispatch) => {
    try {
      const [err, resp] = await to(DataService.getCarTypes());
      if (err) {
        const {status} = err.response;
        if(status === DataService.UNAUTHORIZED) {
          toast.error("Unauthorized or session timed out. Kindly relogin", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        } else {
          toast.error("Something went wrong", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        }
        throwError(err);
      }
      dispatch(carTypeSuccess(resp.data));
      return;
    } catch (error) {
      throwError(error);
    }
  };
}

export function deleteDriver(driver) {
  return async (dispatch) => {
    dispatch(driverRequest());
    try {
      if (!driver) {
        throwError("");
      }
      if (driver.driverProfiles) {
        const [err, resp] = await to(
          DataService.deleteDriverProfile(driver.driverProfiles.id)
        );
      }
      if (driver.carProfiles && driver.carProfiles.length) {
        driver.carProfiles.map((cp) => {
          DataService.deleteCarProfile(cp.id);
        });
      }
      const [err, resp] = await to(DataService.deleteDriver(driver.id));
      if (err) {
        const {status} = err.response;
        if(status === DataService.UNAUTHORIZED) {
          toast.error("Unauthorized or session timed out. Kindly relogin", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        } else {
          toast.error("Something went wrong", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        }
        throwError(err);
      }
      return dispatch(getDrivers());
    } catch (error) {
      dispatch(driverError(error));
      throwError(error);
    }
  };
}

export function updateCarDetail(id, body) {
  return async (dispatch) => {
    try {
      const [err, resp] = await to(DataService.updateCarDetails(body));
      if (err) {
        const {status} = err.response;
        if(status === DataService.UNAUTHORIZED) {
          toast.error("Unauthorized or session timed out. Kindly relogin", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        } else {
          toast.error("Something went wrong", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        }
        throwError(err);
      }
      dispatch(getDriverById(id));
      return;
    } catch (error) {
      dispatch(driverError(error));
      throwError(error);
    }
  };
}

export function getUploadedDocuments(id) {
  return async (dispatch) => {
    try {
      const [err, resp] = await to(DataService.getAllUploadedDocument(id));
      if (err) {
        const {status} = err.response;
        if(status === DataService.UNAUTHORIZED) {
          toast.error("Unauthorized or session timed out. Kindly relogin", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        } else {
          toast.error("Something went wrong", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        }
        throwError(err);
      }
      dispatch(driverDocuments(resp.data.doc.rows || []));
      return;
    } catch (error) {
      dispatch(driverError(error));
      throwError(error);
    }
  };
}

export function updateStripAccount(body) {
  return async (dispatch) => {
    try {
      let userId = body.userId;
      delete body[userId];
      const [err, resp] = await to(DataService.updateStripeAcc(body));
      if (err) {
        const {status} = err.response;
        if(status === DataService.UNAUTHORIZED) {
          toast.error("Unauthorized or session timed out. Kindly relogin", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        } else {
          toast.error("Something went wrong", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        }
        throwError(err);
      }
      dispatch(getDriverById(userId));
      if(resp.data && resp.data.success) {
        toast.success("Stripe account updated successfully!", {
          hideProgressBar: true,
          theme: "colored",
          closeButton: true,
          delay: 200
        });
      } else {
        toast.error(resp.data.message, {
          hideProgressBar: true,
          theme: "colored",
          closeButton: true,
          delay: 200
        });
      }
      return;
    } catch (error) {
      dispatch(driverError(error));
      throwError(error);
    }
  };
}
