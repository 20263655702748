import { produce } from "immer";
import ACTIONS from "../actions/types.actions";
const ad = produce((draft, action) => {
  switch (action.type) {
    case ACTIONS.AD_REQUEST:
      draft.loading = true;
      return draft;
    case ACTIONS.AD_SUCCESS:
      draft.loading = false;
      draft.data = action.response;
      return draft;
    case ACTIONS.AD_ERROR:
      draft.loading = false;
      draft.error = action.error;
      return draft;
    case ACTIONS.AD_IMAGES:
      draft.loading = false;
      draft.error = action.error;
      let index = draft.images.findIndex(item => item.id === action.images.id)
      if (index === -1) {
        draft.images.push(action.images)
      } else {
        draft.images[index] = action.images;
      }
      // if (action.images && !draft.images.includes(item => item === action.images)) draft.images.push(action.images)
      return draft;
    default:
      return draft;
  }
});

export default ad;
