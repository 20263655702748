import { toast } from "react-toastify";
import DataService from "../../services/data.service";
import { throwError, to } from "../../utils/utils";
import ACTIONS from "./types.actions";

export function adRequest() {
  return { type: ACTIONS.AD_REQUEST };
}

export function adSuccess(response) {
  return { type: ACTIONS.AD_SUCCESS, response };
}

export function adError(error) {
  return { type: ACTIONS.AD_ERROR, error };
}

export function adImages(images) {
  return { type: ACTIONS.AD_IMAGES, images };
}

export function getAds() {
  return async (dispatch) => {
    dispatch(adRequest());
    try {
      const [err, resp] = await to(DataService.getAds());
      if (err) {
        const {status} = err.response;
        if(status === DataService.UNAUTHORIZED) {
          toast.error("Unauthorized or session timed out. Kindly relogin", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        } else {
          toast.error("Something went wrong", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        }
        throwError(err);
      }
      dispatch(adSuccess(resp.data.data || []));
      return;
    } catch (error) {
      dispatch(adError(error));
      throwError(error);
    }
  };
}

export function uploadAd(body) {
  return async (dispatch) => {
    try {
      dispatch(adRequest());
      const [err, res] = await to(DataService.uploadAd(body));
      if (err) {
        const {status} = err.response;
        if(status === DataService.UNAUTHORIZED) {
          toast.error("Unauthorized or session timed out. Kindly relogin", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        } else {
          toast.error("Something went wrong", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        }
        throwError(err);
      }
      dispatch(checkUploadedFile(body.fileExtension, res.data.uploadUrl, body.fileObj, res.data.keyName, body.adObj));
      return;
    } catch (error) {
      dispatch(adError(error));
      throwError(error);
    }
  };
}

function checkUploadedFile(type, url, formObj, keyName, adObj) {
  return async (dispatch) => {
    try {
      const [err, res] = await to(DataService.checkFileUpload(type, url, formObj));
      if (err) {
        const {status} = err.response;
        if(status === DataService.UNAUTHORIZED) {
          toast.error("Unauthorized or session timed out. Kindly relogin", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        } else {
          toast.error("Something went wrong", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        }
        throwError(err);
      }
      adObj.keyName = keyName;
      dispatch(updateAd(adObj));
      return;
    } catch (error) {
      dispatch(adError(error));
      throwError(error);
    }
  };
}

export function getUploadedFile(body) {
  return async (dispatch) => {
    try {
      const [err, res] = await to(DataService.getUploadedFile({fileKey: body.keyName}));
      if (err) {
        const {status} = err.response;
        if(status === DataService.UNAUTHORIZED) {
          toast.error("Unauthorized or session timed out. Kindly relogin", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        } else {
          toast.error("Something went wrong", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        }
        throwError(err);
      }
      dispatch(adImages({ id: body.id, url: res.data.url}));
      return;
    } catch (error) {
      dispatch(adError(error));
      throwError(error);
    }
  };
}

export function updateAd(body) {
  return async (dispatch) => {
    try {
      const [err, res] = await to(DataService.updateAd(body));
      if (err) {
        const {status} = err.response;
        if(status === DataService.UNAUTHORIZED) {
          toast.error("Unauthorized or session timed out. Kindly relogin", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        } else {
          toast.error("Something went wrong", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        }
        throwError(err);
      }
      if(res.data && res.data.success) {
        toast.success(res.data.message, {
          hideProgressBar: true,
          theme: "colored",
          closeButton: true,
        });
      } else {
        toast.error(res.data.message, {
          hideProgressBar: true,
          theme: "colored",
          closeButton: true,
        });
      }
      dispatch(getAds());
      return;
    } catch (error) {
      dispatch(adError(error));
      throwError(error);
    }
  };
}