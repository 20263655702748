import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import Aux from "../../hoc/Aux_";
import ROUTES from "../../routes/constant.route";
import routes from "../../routes/list.route";
import { isLoggedIn } from "../../utils/auth.utils";

class AuthLayout extends Component {
  render() {
    return (
      <Aux>
        {isLoggedIn() && <Redirect to={ROUTES.CUSTOMERS.path} />}
        <Switch>
          {routes &&
            routes.map((route, index) => {
              return !route.isPrivate ? (
                <Route key={`key-${index}`} path={route.path} component={route.component} />
              ) : null;
            })}
        </Switch>
      </Aux>
    );
  }
}

export default withRouter(AuthLayout);
