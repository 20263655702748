import $ from "jquery";
import { find, orderBy } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Lightbox from "react-image-lightbox";
import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import AUX from "../../hoc/Aux_";
import {
  getDriverById,
  getUploadedDocuments,
  updateStripAccount
} from "../../store/actions/driver.actions";
import { ToastContainer, toast } from 'react-toastify';
import DataService from "../../services/data.service";
import { throwError, to } from "../../utils/utils";

const DriversDetail = (props) => {
  const [driver, setDriver] = useState(null);
  const [editable, setEditable] = useState(false);
  const [popup, setPopup] = useState(null);
  const [selectedCarType, setSelectedCarType] = useState(null);
  const [error, setError] = useState(null);
  const {
    loading,
    driverDetail,
    carTypes,
    allDocuments,
    getDriverById,
    getUploadedDocuments,
    updateStripAccount
  } = props;

  let { id } = useParams();

  useEffect(() => {
    $("#now_routing")
      .empty()
      .append("Driver Details");

    // if (props.location.data) {
    //   setDriver(props.location.data);
    //   getUploadedDocuments(props.location.data.id)
    // } else {
    //   getDriverById(id);
    //   getUploadedDocuments(id)
    // }
    getDriverById(id);
    getUploadedDocuments(id)
  }, []);

  useEffect(() => {
    console.log("Driver Detail")
    if (driverDetail && driverDetail.id === id) {
      setDriver(driverDetail);
      let result = carTypes.find((item) => {
        return driverDetail && driverDetail.Drivers && driverDetail.Drivers.Cars && driverDetail.Drivers.Cars.carType === item.carType;
      });
      if (result) setSelectedCarType(result);
      else setSelectedCarType(null);
    }
  }, [driverDetail]);

  useEffect(() => {
    console.log("Driver")
    if (driver) {
      let result = carTypes.find((item) => {
        return driver && driver.Drivers && driver.Drivers.Cars && driver.Drivers.Cars.carType === item.carType;
      });
      if (result) setSelectedCarType(result);
      else setSelectedCarType(null);
    }
  }, [driver]);

  const updateCarDetails = async (driverDetail, v) => {
    if (driverDetail && driverDetail.Drivers && driverDetail.Drivers.Cars && driverDetail.Drivers.Cars && driverDetail.Drivers.Cars.id) {
      if (v !== 'selectCarType') {
        let body = JSON.parse(JSON.stringify(driverDetail.Drivers.Cars));
        body.carType = v;
        try {
          const [err, resp] = await to(DataService.updateCarDetails(body));
          if (err) {
            toast.error("Something went wrong", {
              hideProgressBar: true,
              theme: "colored",
              closeButton: true,
            });
            throwError(err);
          }
          if (resp && resp.data && resp.data.success) {
            toast.success("Cartype updated successfully!", {
              hideProgressBar: true,
              theme: "colored",
              closeButton: true,
            });
            //driver.Drivers.Cars.carType
            setDriver(driver => ({ ...driver, Drivers: { ...driver.Drivers, Cars: { ...driver.Drivers.Cars, carType: v } } }));
          } else {
            toast.error(resp.data.message, {
              hideProgressBar: true,
              theme: "colored",
              closeButton: true,
            });
          }
        } catch (error) {
          toast.error(error.message, {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        }
      }
    } else {
      toast.error("Car details not found", {
        hideProgressBar: true,
        theme: "colored",
        closeButton: true,
      });
    }
  }

  const checkDocumentPresent = (fieldKey) => {
    return allDocuments.find(item => item.fieldKey === fieldKey) ? true : false
  }

  const getPresentDocumentKey = (fieldKey) => {
    let data = allDocuments.find(item => item.fieldKey === fieldKey)
    return data && data.keyName ? data.keyName : ""
  }

  const getUplodedPhoto = async (fieldKey) => {
    try {
      const [err, resp] = await to(DataService.getUploadedFile({ fileKey: getPresentDocumentKey(fieldKey) }));
      if (err) {
        toast.error("Something went wrong", {
          hideProgressBar: true,
          theme: "colored",
          closeButton: true,
        });
        throwError(err);
      }
      return resp.data.url;
    } catch (error) {
      toast.error(error.message, {
        hideProgressBar: true,
        theme: "colored",
        closeButton: true,
      });
    }
  };

  const approveDriverDetail = async (body) => {
    try {
      const [err, resp] = await to(DataService.approveDriver(body));
      if (err) {
        toast.error("Something went wrong", {
          hideProgressBar: true,
          theme: "colored",
          closeButton: true,
        });
        throwError(err);
      }
      if (resp && resp.data && resp.data.success) {
        let updated = {
          ...driver,
          isApproved: driver.isApproved === 1 ? 0 : 1,
        };
        setDriver(updated);
        if (driver.isApproved === 0) {
          toast.success(resp.data.message, {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
            delay: 200
          });
        } else {
          toast.success("Driver disapproved successfully!", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
            delay: 200
          });
        }
      } else {
        toast.error(resp.data.message, {
          hideProgressBar: true,
          theme: "colored",
          closeButton: true,
        });
      }
    } catch (error) {
      toast.error(error.message, {
        hideProgressBar: true,
        theme: "colored",
        closeButton: true,
      });
    }
  };

  const updateCar = (v) => {
    if (driverDetail) {
      updateCarDetails(driverDetail, v);
    } else if (driver) {
      updateCarDetails(driver, v);
    } else {
      toast.error("No selected car type", {
        hideProgressBar: true,
        theme: "colored",
        closeButton: true,
      });
    }
  };

  const updateStrip = (userId, id, stripeAccountId) => {
    if (driver && driver.Drivers && driver.Drivers.id) {
      if (stripeAccountId) {
        let body = {
          userId: userId,
          id: id,
          stripeAccountId: stripeAccountId
        }
        updateStripAccount(body);
      } else {
        toast.error("Provide Stripe Account", {
          hideProgressBar: true,
          theme: "colored",
          closeButton: true,
        });
      }
    } else {
      toast.error("Driver details not found", {
        hideProgressBar: true,
        theme: "colored",
        closeButton: true,
      });
    }
  }

  const handleChange = (target) => {
    // let updatedDriver = {...driver.Drivers, stripeAccountId: target.value};
    setDriver(driver => ({ ...driver, Drivers: { ...driver.Drivers, stripeAccountId: target.value } }));
  }

  return (
    <AUX>
      <div>
        <ToastContainer style={{ ['--toastify-toast-min-height']: "40px" }} />
      </div>
      {error ? (
        <SweetAlert error title={error.title} onConfirm={() => setError(null)}>
          {error.desc}
        </SweetAlert>
      ) : null}

      {popup ? (
        <Lightbox
          mainSrc={popup.url}
          onCloseRequest={() => setPopup(null)}
          imageCaption={popup.caption}
          zoomInLabel="Zoom in"
        />
      ) : null}

      {loading || !driver || !carTypes ? (
        <div id="status">
          <div className="spinner" />
        </div>
      ) : (
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6">
                <div className="card m-b-20">
                  {driver ? (
                    <div className="card-body">
                      <h4 className="mt-0 header-title">General Profile</h4>
                      <p className="text-muted m-b-30 font-14">
                        Basic driver profile fields
                      </p>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Driver ID
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="driver ID"
                            id="example-text-input"
                            value={driver.id}
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          First Name
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="First Name"
                            id="example-text-input"
                            value={driver.firstName}
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Last Name
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Last Name"
                            id="example-text-input"
                            value={driver.lastName}
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Email
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Email"
                            id="example-text-input"
                            value={driver.email}
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Phone
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Phone No"
                            id="example-text-input"
                            value={driver.phone}
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Emergency Contact
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="N/A"
                            id="example-text-input"
                            value={driver.emergencyContact}
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      {/* <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Username
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Username"
                            id="example-text-input"
                            value={driver && driver.Drivers && driver.Drivers.userName}
                            disabled={!editable}
                          />
                        </div>
                      </div> */}
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Date of Birth
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Date of Birth"
                            id="example-text-input"
                            value={driver.dob}
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Address
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Address"
                            id="example-text-input"
                            value={driver.address}
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          City
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="City"
                            id="example-text-input"
                            value={driver.city}
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Driver Profile Picture
                        </label>
                        <div className="col-sm-10">
                          {checkDocumentPresent("PROFILE") ? (
                            <a
                              href="javascript:void(0);"
                              className="m-r-15 text-muted"
                              onClick={() => {
                                getUplodedPhoto("PROFILE").then(res => {
                                  let url = res;
                                  setPopup({
                                    url,
                                    caption: "Driving License",
                                  });
                                })
                              }}
                            >
                              View
                            </a>
                          ) : (
                            <div>{"N/A"}</div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Stripe Account ID
                        </label>
                        <div className="col-sm-10">
                          {/* <input
                            className="form-control"
                            type="text"
                            placeholder="Stripe Account ID"
                            id="example-text-input"
                            value={driver.Drivers.stripeAccountId}
                            disabled={editable}
                          /> */}
                          <div className="input-group">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Stripe Account ID"
                              id="example-text-input"
                              value={driver.Drivers.stripeAccountId}
                              disabled={editable}
                              name="stripeAccountId"
                              onChange={(e) => {
                                handleChange(e.target);
                              }}
                            />
                            <div className="input-group-append">
                              <button onClick={() =>
                                updateStrip(driver.id, driver.Drivers.id, driver.Drivers.stripeAccountId)
                              }
                                className="btn btn-primary waves-effect waves-light" type="button">Update</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-datetime-local-input"
                          className="col-sm-2 col-form-label"
                        >
                          Created At
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="datetime-local"
                            value={moment(driver.createdAt).format(
                              "YYYY-MM-DDTHH:mm:ss"
                            )}
                            id="example-datetime-local-input"
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-datetime-local-input"
                          className="col-sm-2 col-form-label"
                        >
                          Last Updated At
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="datetime-local"
                            value={moment(driver.updatedAt).format(
                              "YYYY-MM-DDTHH:mm:ss"
                            )}
                            id="example-datetime-local-input"
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-datetime-local-input"
                          className="col-sm-2 col-form-label"
                        >
                          Driver Status
                        </label>
                        <div className="col-sm-2 col-form-label">
                          {/* {
                            driver.isApproved === 1 && driver.isOnline === 1 ? (
                              <span className="badge badge-success">Online</span>
                            ) : driver.isRideAssigned === 1 ? (
                              <span className="badge badge-teal">On Trip</span>
                            ) : driver.isApproved === 1 && driver.isOnline === 0 ? (
                              <span className="badge badge-blue-grey">
                                Offline
                              </span>
                            ) : (
                              <span className="badge badge-danger">
                                Not Approved
                              </span>
                            )
                          } */}
                          {!driver.isApproved || !driver.isApproved === 1 ? (
                            <span className="badge badge-danger">
                              Not Approved
                            </span>
                          ) : driver.isRideAssigned === 1 ? (
                            <span className="badge badge-teal">On Trip</span>
                          ) : driver.isOnline === 1 ? (
                            <span className="badge badge-success">Online</span>
                          ) : (
                            <span className="badge badge-blue-grey">
                              Offline
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-datetime-local-input"
                          className="col-sm-2 col-form-label"
                        >
                          Car Type
                        </label>
                        <div className="col-sm-4 col-form-label">
                          <div className="input-group">
                            <select
                              className="form-control"
                              value={
                                selectedCarType && selectedCarType.carType
                              }
                              onChange={(e) => {
                                let v = e.target.value;
                                if (v !== "selectCarType") {
                                  setSelectedCarType(
                                    find(carTypes, ["carType", v])
                                  );
                                }
                                updateCar(v);
                              }}
                            >
                              <option value="selectCarType">
                                Select Car Type
                              </option>
                              {carTypes && carTypes.length
                                ? orderBy(
                                  carTypes,
                                  ["carType"],
                                  ["desc"]
                                ).map((carType) => {
                                  return (
                                    <option
                                      value={carType.carType}
                                      key={`key-${carType.carType}`}
                                    >
                                      {carType.carType}
                                    </option>
                                  );
                                })
                                : null}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-datetime-local-input"
                          className="col-sm-2 col-form-label"
                        >
                          Is Approved
                        </label>
                        <div className="col-sm-2 col-form-label">
                          <input
                            type="checkbox"
                            id="switch1"
                            checked={driver.isApproved === 1 ? "checked" : ""}
                            switch="bool"
                            onChange={() => {
                              if (!selectedCarType) {
                                setError({
                                  title: "Error",
                                  desc: "Please select Car Type to approve.",
                                });
                              } else { //carType: selectedCarType.carType,
                                let body = {
                                  isApproved: driver.isApproved === 1 ? false : true,
                                  id: driver.id,
                                };
                                approveDriverDetail(body);
                              }
                            }}
                          />
                          <label
                            htmlFor="switch1"
                            data-on-label="Yes"
                            data-off-label="No"
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card m-b-20">
                  {driver && driver.Drivers ? (
                    <div className="card-body">
                      <h4 className="mt-0 header-title">Documents</h4>
                      <p className="text-muted m-b-30 font-14" />
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          License Number
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="License Number"
                            id="example-text-input"
                            value={driver.Drivers.licenseNo || "N/A"}
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          License Version
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="License Version"
                            id="example-text-input"
                            value={
                              driver.Drivers.licenseVersion || "N/A"
                            }
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          PSL
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="License Version"
                            id="example-text-input"
                            value={
                              checkDocumentPresent("DRIVER_LICENSE_PSL")
                                ? "Yes"
                                : "No" || "N/A"
                            }
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          License Front
                        </label>
                        <div className="col-sm-10">
                          {checkDocumentPresent("DRIVER_LICENSE_FRONT") ? (
                            <a
                              href="javascript:void(0);"
                              className="m-r-15 text-muted"
                              onClick={() => {
                                getUplodedPhoto("DRIVER_LICENSE_FRONT").then(res => {
                                  let url = res;
                                  setPopup({
                                    url,
                                    caption: "Driving License Front",
                                  });
                                })
                              }}
                            >
                              View
                            </a>
                          ) : (
                            <div>{"N/A"}</div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          License Back
                        </label>
                        <div className="col-sm-10">
                          {checkDocumentPresent("DRIVER_LICENSE_BACK") ? (
                            <a
                              href="javascript:void(0);"
                              className="m-r-15 text-muted"
                              onClick={() => {
                                getUplodedPhoto("DRIVER_LICENSE_BACK").then(res => {
                                  let url = res;
                                  setPopup({
                                    url,
                                    caption: "Driving License Back",
                                  });
                                })
                              }}
                            >
                              View
                            </a>
                          ) : (
                            <div>{"N/A"}</div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          PSL
                        </label>
                        <div className="col-sm-10">
                          {checkDocumentPresent("DRIVER_LICENSE_PSL") ? (
                            <a
                              href="javascript:void(0);"
                              className="m-r-15 text-muted"
                              onClick={() => {
                                getUplodedPhoto("DRIVER_LICENSE_PSL").then(res => {
                                  let url = res;
                                  setPopup({
                                    url,
                                    caption: "Driving License PSL",
                                  });
                                })
                              }}
                            >
                              View
                            </a>
                          ) : (
                            <div>{"N/A"}</div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          TSL
                        </label>
                        <div className="col-sm-10">
                          {checkDocumentPresent("DRIVER_LICENSE_TSL") ? (
                            <a
                              href="javascript:void(0);"
                              className="m-r-15 text-muted"
                              onClick={() => {
                                getUplodedPhoto("DRIVER_LICENSE_TSL").then(res => {
                                  let url = res;
                                  setPopup({
                                    url,
                                    caption: "Driving License TSL",
                                  });
                                })
                              }}
                            >
                              View
                            </a>
                          ) : (
                            <div>{"N/A"}</div>
                          )}
                        </div>
                      </div>
                      {/* <div className="form-group row align-items-center">
                      <label htmlFor="example-text-input" className="col-sm-2 col-form-label">
                        PSL/TSL Back
                      </label>
                      {driver.driverProfiles.pslBack ? (
                        <img
                          src={`${BASE_URL_IMAGES}${driver.driverProfiles.pslBack}`}
                          alt="Image"
                          className="img-fluid"
                          style={{ maxWidth: "70px" }}
                          onClick={() =>
                            setPopup({
                              url: `${BASE_URL_IMAGES}${driver.driverProfiles.pslBack}`,
                              caption: "PSL Back",
                            })
                          }
                        />
                      ) : driver.driverProfiles.tslBack ? (
                        <img
                          src={`${BASE_URL_IMAGES}${driver.driverProfiles.tslBack}`}
                          alt="Image"
                          className="img-fluid"
                          style={{ maxWidth: "70px" }}
                          onClick={() =>
                            setPopup({
                              url: `${BASE_URL_IMAGES}${driver.driverProfiles.tslBack}`,
                              caption: "TSL Back",
                            })
                          }
                        />
                      ) : (
                        <div className="col-sm-10">{"N/A"}</div>
                      )}
                    </div> */}
                      <div className="form-group row align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Driver ID Front
                        </label>
                        <div className="col-sm-10">
                          {checkDocumentPresent("DRIVER_ID_CARD_FRONT") ? (
                            <a
                              href="javascript:void(0);"
                              className="m-r-15 text-muted"
                              onClick={() => {
                                getUplodedPhoto("DRIVER_ID_CARD_FRONT").then(res => {
                                  let url = res;
                                  setPopup({
                                    url,
                                    caption: "Driver ID Card Front",
                                  });
                                })
                              }}
                            >
                              View
                            </a>
                          ) : (
                            <div>{"N/A"}</div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Driver ID Back
                        </label>
                        <div className="col-sm-10">
                          {checkDocumentPresent("DRIVER_ID_CARD_BACK") ? (
                            <a
                              href="javascript:void(0);"
                              className="m-r-15 text-muted"
                              onClick={() => {
                                getUplodedPhoto("DRIVER_ID_CARD_BACK").then(res => {
                                  let url = res;
                                  setPopup({
                                    url,
                                    caption: "Driver ID Card Back",
                                  });
                                })
                              }}
                            >
                              View
                            </a>
                          ) : (
                            <div>{"N/A"}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card m-b-20">
                  {driver && driver.Drivers && driver.Drivers.Cars ? (
                    <div className="card-body">
                      <h4 className="mt-0 header-title">Car Profile</h4>
                      <p className="text-muted m-b-30 font-14" />
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Car Type
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Car Type"
                            id="example-text-input"
                            value={driver.Drivers.Cars.carType || "N/A"}
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Car Model
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Car Model"
                            id="example-text-input"
                            value={
                              driver.Drivers.Cars.carModal || "N/A"
                            }
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Car Color
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Car Color"
                            id="example-text-input"
                            value={driver.Drivers.Cars.carColor || "N/A"}
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Registration Number
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Registration Number"
                            id="example-text-input"
                            value={
                              driver.Drivers.Cars.regNo || "N/A"
                            }
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Date of Manufacture
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Date of Manufacture"
                            id="example-text-input"
                            value={
                              driver.Drivers.Cars.dom || "N/A"
                            }
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Date of Registration
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Date of Registration"
                            id="example-text-input"
                            value={
                              driver.Drivers.Cars.dor || "N/A"
                            }
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Mileage
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Mileage"
                            id="example-text-input"
                            value={driver.Drivers.Cars.mileage || "N/A"}
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Insurance Number
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Insurance Number"
                            id="example-text-input"
                            value={
                              driver.Drivers.Cars.insuranceNo || "N/A"
                            }
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Insurance Company
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Insurance Company"
                            id="example-text-input"
                            value={
                              driver.Drivers.Cars.insuranceCompany || "N/A"
                            }
                            disabled={!editable}
                          />
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Insurance Paper Image
                        </label>
                        <div className="col-sm-10">
                          {checkDocumentPresent("INSURANCE_PAPER") ? (
                            <a
                              href="javascript:void(0);"
                              className="m-r-15 text-muted"
                              onClick={() => {
                                getUplodedPhoto("INSURANCE_PAPER").then(res => {
                                  let url = res;
                                  setPopup({
                                    url,
                                    caption: "Insurance Paper",
                                  });
                                })
                              }}
                            >
                              View
                            </a>
                          ) : (
                            <div>{"N/A"}</div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Exterior Image (Front)
                        </label>
                        <div className="col-sm-10">
                          {checkDocumentPresent("EXTERIOR_IMAGE_FRONT") ? (
                            <a
                              href="javascript:void(0);"
                              className="m-r-15 text-muted"
                              onClick={() => {
                                getUplodedPhoto("EXTERIOR_IMAGE_FRONT").then(res => {
                                  let url = res;
                                  setPopup({
                                    url,
                                    caption: "Exterior Image Front",
                                  });
                                })
                              }}
                            >
                              View
                            </a>
                          ) : (
                            <div>{"N/A"}</div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Exterior Image (Back)
                        </label>
                        <div className="col-sm-10">
                          {checkDocumentPresent("EXTERIOR_IMAGE_BACK") ? (
                            <a
                              href="javascript:void(0);"
                              className="m-r-15 text-muted"
                              onClick={() => {
                                getUplodedPhoto("EXTERIOR_IMAGE_BACK").then(res => {
                                  let url = res;
                                  setPopup({
                                    url,
                                    caption: "Exterior Image Back",
                                  });
                                })
                              }}
                            >
                              View
                            </a>
                          ) : (
                            <div>{"N/A"}</div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Exterior Image (Left)
                        </label>
                        <div className="col-sm-10">
                          {checkDocumentPresent("EXTERIOR_IMAGE_LEFT") ? (
                            <a
                              href="javascript:void(0);"
                              className="m-r-15 text-muted"
                              onClick={() => {
                                getUplodedPhoto("EXTERIOR_IMAGE_LEFT").then(res => {
                                  let url = res;
                                  setPopup({
                                    url,
                                    caption: "Exterior Image Left",
                                  });
                                })
                              }}
                            >
                              View
                            </a>
                          ) : (
                            <div>{"N/A"}</div>
                          )}
                        </div>
                      </div>
                      {/* <div className="form-group row align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Driver Profile Picture
                        </label>
                        {driver.carProfiles.outsideRight ? (
                          <img
                            src={`${BASE_URL_IMAGES}${
                              driver.carProfiles.outsideRight
                            }`}
                            alt="Image"
                            className="img-fluid"
                            style={{ maxWidth: "70px" }}
                            onClick={() =>
                              setPopup({
                                url: `${BASE_URL_IMAGES}${
                                  driver.carProfiles.outsideRight
                                }`,
                                caption: "Driver Profile Picture",
                              })
                            }
                          />
                        ) : (
                          <div className="col-sm-10">{"N/A"}</div>
                        )}
                      </div> */}
                      <div className="form-group row align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Interior Image (Front)
                        </label>
                        <div className="col-sm-10">
                          {checkDocumentPresent("INTERIOR_IMAGE_FRONT") ? (
                            <a
                              href="javascript:void(0);"
                              className="m-r-15 text-muted"
                              onClick={() => {
                                getUplodedPhoto("INTERIOR_IMAGE_FRONT").then(res => {
                                  let url = res;
                                  setPopup({
                                    url,
                                    caption: "Interior Image Front",
                                  });
                                })
                              }}
                            >
                              View
                            </a>
                          ) : (
                            <div>{"N/A"}</div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Interior Image (Back)
                        </label>
                        <div className="col-sm-10">
                          {checkDocumentPresent("INTERIOR_IMAGE_BACK") ? (
                            <a
                              href="javascript:void(0);"
                              className="m-r-15 text-muted"
                              onClick={() => {
                                getUplodedPhoto("INTERIOR_IMAGE_BACK").then(res => {
                                  let url = res;
                                  setPopup({
                                    url,
                                    caption: "Interior Image Back",
                                  });
                                })
                              }}
                            >
                              View
                            </a>
                          ) : (
                            <div>{"N/A"}</div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Interior Image (Left)
                        </label>
                        <div className="col-sm-10">
                          {checkDocumentPresent("INTERIOR_IMAGE_LEFT") ? (
                            <a
                              href="javascript:void(0);"
                              className="m-r-15 text-muted"
                              onClick={() => {
                                getUplodedPhoto("INTERIOR_IMAGE_LEFT").then(res => {
                                  let url = res;
                                  setPopup({
                                    url,
                                    caption: "Interior Image Left",
                                  });
                                })
                              }}
                            >
                              View
                            </a>
                          ) : (
                            <div>{"N/A"}</div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Interior Image (Right)
                        </label>
                        <div className="col-sm-10">
                          {checkDocumentPresent("INTERIOR_IMAGE_RIGHT") ? (
                            <a
                              href="javascript:void(0);"
                              className="m-r-15 text-muted"
                              onClick={() => {
                                getUplodedPhoto("INTERIOR_IMAGE_RIGHT").then(res => {
                                  let url = res;
                                  setPopup({
                                    url,
                                    caption: "Interior Image Right",
                                  });
                                })
                              }}
                            >
                              View
                            </a>
                          ) : (
                            <div>{"N/A"}</div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Fitness Certificate (Front)
                        </label>
                        <div className="col-sm-10">
                          {checkDocumentPresent("FITNESS_CERTIFICATE_FRONT") ? (
                            <a
                              href="javascript:void(0);"
                              className="m-r-15 text-muted"
                              onClick={() => {
                                getUplodedPhoto("FITNESS_CERTIFICATE_FRONT").then(res => {
                                  let url = res;
                                  setPopup({
                                    url,
                                    caption: "Fitness Certificate Front",
                                  });
                                })
                              }}
                            >
                              View
                            </a>
                          ) : (
                            <div>{"N/A"}</div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Fitness Certificate (Back)
                        </label>
                        <div className="col-sm-10">
                          {checkDocumentPresent("FITNESS_CERTIFICATE_BACK") ? (
                            <a
                              href="javascript:void(0);"
                              className="m-r-15 text-muted"
                              onClick={() => {
                                getUplodedPhoto("FITNESS_CERTIFICATE_BACK").then(res => {
                                  let url = res;
                                  setPopup({
                                    url,
                                    caption: "Fitness Certificate Back",
                                  });
                                })
                              }}
                            >
                              View
                            </a>
                          ) : (
                            <div>{"N/A"}</div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Registration Document (Front)
                        </label>
                        <div className="col-sm-10">
                          {checkDocumentPresent("REGISTRATION_DOCUMENT_FRONT") ? (
                            <a
                              href="javascript:void(0);"
                              className="m-r-15 text-muted"
                              onClick={() => {
                                getUplodedPhoto("REGISTRATION_DOCUMENT_FRONT").then(res => {
                                  let url = res;
                                  setPopup({
                                    url,
                                    caption: "Registration Document Front",
                                  });
                                })
                              }}
                            >
                              View
                            </a>
                          ) : (
                            <div>{"N/A"}</div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row align-items-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Registration Document (Back)
                        </label>
                        <div className="col-sm-10">
                          {checkDocumentPresent("REGISTRATION_DOCUMENT_BACK") ? (
                            <a
                              href="javascript:void(0);"
                              className="m-r-15 text-muted"
                              onClick={() => {
                                getUplodedPhoto("REGISTRATION_DOCUMENT_BACK").then(res => {
                                  let url = res;
                                  setPopup({
                                    url,
                                    caption: "Registration Document Back",
                                  });
                                })
                              }}
                            >
                              View
                            </a>
                          ) : (
                            <div>{"N/A"}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </AUX>
  );
};

const mapStatetoProps = (state) => {
  return {
    loading: state.driver.loading,
    driverDetail: state.driver.detail,
    error: state.driver.error,
    carTypes: [{ carType: "Small" }, { carType: "Medium" }, { carType: "Large" }],
    allDocuments: state.driver.documents
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDriverById: (id) => dispatch(getDriverById(id)),
  getUploadedDocuments: (id) => dispatch(getUploadedDocuments(id)),
  updateStripAccount: (body) => dispatch(updateStripAccount(body))
});

export default withRouter(
  connect(
    mapStatetoProps,
    mapDispatchToProps
  )(DriversDetail)
);
