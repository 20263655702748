import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import mainbuilder from "../../containers/mainbuilder/mainbuilder";
import Aux from "../../hoc/Aux_";
import ROUTES from "../../routes/constant.route";
import routes from "../../routes/list.route";
import { isLoggedIn } from "../../utils/auth.utils";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";

class MainLayout extends Component {
  render() {
    return (
      <Aux>
        {!isLoggedIn() && <Redirect to={ROUTES.LOGIN.path} />}
        <main>
          <div id="wrapper">
            <Sidebar />
            <div className="content-page">
              <div className="content" id="content-id">
                <Header />
                <Switch>
                  {routes &&
                    routes.map((route, index) => {
                      return route.isPrivate ? (
                        <Route path={route.path} key={`key-${index}`} exact={true}>
                          {route.component}
                        </Route>
                      ) : null;
                    })}
                  {/* THEME WORK */}
                  <Route path="/" component={mainbuilder} />
                </Switch>
              </div>
              <Footer />
            </div>
          </div>
        </main>
      </Aux>
    );
  }
}

export default withRouter(MainLayout);
