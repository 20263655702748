import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import { Link } from "react-router-dom";

class Icons_typicons extends Component {
  render() {
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Examples</h4>
                    <p className="text-muted m-b-30 font-14">
                      {" "}
                      Use{" "}
                      <code>
                        &lt;i className="typcn typcn-chart-pie-outline
                        "&gt;&lt;/i&gt;
                      </code>
                      .
                    </p>

                    <div className="row icon-demo-content">
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-chart-pie-outline" /> typcn
                        typcn-chart-pie-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-chart-pie" /> typcn
                        typcn-chart-pie
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-chevron-left-outline" /> typcn
                        typcn-chevron-left-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-chevron-left" /> typcn
                        typcn-chevron-left
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-chevron-right-outline" />{" "}
                        typcn typcn-chevron-right-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-chevron-right" /> typcn
                        typcn-chevron-right
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-clipboard" /> typcn
                        typcn-clipboard
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-cloud-storage" /> typcn
                        typcn-cloud-storage
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-cloud-storage-outline" />{" "}
                        typcn typcn-cloud-storage-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-code-outline" /> typcn
                        typcn-code-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-code" /> typcn typcn-code
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-coffee" /> typcn typcn-coffee
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-cog-outline" /> typcn
                        typcn-cog-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-cog" /> typcn typcn-cog
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-compass" /> typcn
                        typcn-compass
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-contacts" /> typcn
                        typcn-contacts
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-credit-card" /> typcn
                        typcn-credit-card
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-css3" /> typcn typcn-css3
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-database" /> typcn
                        typcn-database
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-delete-outline" /> typcn
                        typcn-delete-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-delete" /> typcn typcn-delete
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-device-desktop" /> typcn
                        typcn-device-desktop
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-device-laptop" /> typcn
                        typcn-device-laptop
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-device-phone" /> typcn
                        typcn-device-phone
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-device-tablet" /> typcn
                        typcn-device-tablet
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-directions" /> typcn
                        typcn-directions
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-divide-outline" /> typcn
                        typcn-divide-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-divide" /> typcn typcn-divide
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-document-add" /> typcn
                        typcn-document-add
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-document-delete" /> typcn
                        typcn-document-delete
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-document-text" /> typcn
                        typcn-document-text
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-document" /> typcn
                        typcn-document
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-download-outline" /> typcn
                        typcn-download-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-download" /> typcn
                        typcn-download
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-dropbox" /> typcn
                        typcn-dropbox
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-edit" /> typcn typcn-edit
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-eject-outline" /> typcn
                        typcn-eject-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-eject" /> typcn typcn-eject
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-equals-outline" /> typcn
                        typcn-equals-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-equals" /> typcn typcn-equals
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-export-outline" /> typcn
                        typcn-export-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-export" /> typcn typcn-export
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-eye-outline" /> typcn
                        typcn-eye-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-eye" /> typcn typcn-eye
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-feather" /> typcn
                        typcn-feather
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-film" /> typcn typcn-film
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-filter" /> typcn typcn-filter
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-flag-outline" /> typcn
                        typcn-flag-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-flag" /> typcn typcn-flag
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-flash-outline" /> typcn
                        typcn-flash-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-flash" /> typcn typcn-flash
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-flow-children" /> typcn
                        typcn-flow-children
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-flow-merge" /> typcn
                        typcn-flow-merge
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-flow-parallel" /> typcn
                        typcn-flow-parallel
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-flow-switch" /> typcn
                        typcn-flow-switch
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-folder-add" /> typcn
                        typcn-folder-add
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-folder-delete" /> typcn
                        typcn-folder-delete
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-folder-open" /> typcn
                        typcn-folder-open
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-folder" /> typcn typcn-folder
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-gift" /> typcn typcn-gift
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-globe-outline" /> typcn
                        typcn-globe-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-globe" /> typcn typcn-globe
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-group-outline" /> typcn
                        typcn-group-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-group" /> typcn typcn-group
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-headphones" /> typcn
                        typcn-headphones
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-heart-full-outline" /> typcn
                        typcn-heart-full-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-heart-half-outline" /> typcn
                        typcn-heart-half-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-heart-outline" /> typcn
                        typcn-heart-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-heart" /> typcn typcn-heart
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-home-outline" /> typcn
                        typcn-home-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-home" /> typcn typcn-home
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-html5" /> typcn typcn-html5
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-image-outline" /> typcn
                        typcn-image-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-image" /> typcn typcn-image
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-infinity-outline" /> typcn
                        typcn-infinity-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-infinity" /> typcn
                        typcn-infinity
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-info-large-outline" /> typcn
                        typcn-info-large-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-info-large" /> typcn
                        typcn-info-large
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-info-outline" /> typcn
                        typcn-info-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-info" /> typcn typcn-info
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-input-checked-outline" />{" "}
                        typcn typcn-input-checked-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-input-checked" /> typcn
                        typcn-input-checked
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-key-outline" /> typcn
                        typcn-key-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-key" /> typcn typcn-key
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-keyboard" /> typcn
                        typcn-keyboard
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-leaf" /> typcn typcn-leaf
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-lightbulb" /> typcn
                        typcn-lightbulb
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-link-outline" /> typcn
                        typcn-link-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-link" /> typcn typcn-link
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-location-arrow-outline" />{" "}
                        typcn typcn-location-arrow-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-location-arrow" /> typcn
                        typcn-location-arrow
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-location-outline" /> typcn
                        typcn-location-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-location" /> typcn
                        typcn-location
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-lock-closed-outline" /> typcn
                        typcn-lock-closed-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-lock-closed" /> typcn
                        typcn-lock-closed
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-lock-open-outline" /> typcn
                        typcn-lock-open-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-lock-open" /> typcn
                        typcn-lock-open
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-mail" /> typcn typcn-mail
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-map" /> typcn typcn-map
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-media-eject-outline" /> typcn
                        typcn-media-eject-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-media-eject" /> typcn
                        typcn-media-eject
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-media-fast-forward-outline" />{" "}
                        typcn typcn-media-fast-forward-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-media-fast-forward" /> typcn
                        typcn-media-fast-forward
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-media-pause-outline" /> typcn
                        typcn-media-pause-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-media-pause" /> typcn
                        typcn-media-pause
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-media-play-outline" /> typcn
                        typcn-media-play-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-media-play-reverse-outline" />{" "}
                        typcn typcn-media-play-reverse-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-media-play-reverse" /> typcn
                        typcn-media-play-reverse
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-media-play" /> typcn
                        typcn-media-play
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-media-record-outline" /> typcn
                        typcn-media-record-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-media-record" /> typcn
                        typcn-media-record
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-media-rewind-outline" /> typcn
                        typcn-media-rewind-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-media-rewind" /> typcn
                        typcn-media-rewind
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-media-stop-outline" /> typcn
                        typcn-media-stop-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-media-stop" /> typcn
                        typcn-media-stop
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-message-typing" /> typcn
                        typcn-message-typing
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-message" /> typcn
                        typcn-message
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-messages" /> typcn
                        typcn-messages
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-microphone-outline" /> typcn
                        typcn-microphone-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-microphone" /> typcn
                        typcn-microphone
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-minus-outline" /> typcn
                        typcn-minus-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-minus" /> typcn typcn-minus
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-mortar-board" /> typcn
                        typcn-mortar-board
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-news" /> typcn typcn-news
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-notes-outline" /> typcn
                        typcn-notes-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-notes" /> typcn typcn-notes
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-pen" /> typcn typcn-pen
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-pencil" /> typcn typcn-pencil
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-phone-outline" /> typcn
                        typcn-phone-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-phone" /> typcn typcn-phone
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-pi-outline" /> typcn
                        typcn-pi-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-pi" /> typcn typcn-pi
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-pin-outline" /> typcn
                        typcn-pin-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-pin" /> typcn typcn-pin
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-pipette" /> typcn
                        typcn-pipette
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-plane-outline" /> typcn
                        typcn-plane-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-plane" /> typcn typcn-plane
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-plug" /> typcn typcn-plug
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-plus-outline" /> typcn
                        typcn-plus-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-plus" /> typcn typcn-plus
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-point-of-interest-outline" />{" "}
                        typcn typcn-point-of-interest-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-point-of-interest" /> typcn
                        typcn-point-of-interest
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-power-outline" /> typcn
                        typcn-power-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-power" /> typcn typcn-power
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-printer" /> typcn
                        typcn-printer
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-puzzle-outline" /> typcn
                        typcn-puzzle-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-puzzle" /> typcn typcn-puzzle
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-radar-outline" /> typcn
                        typcn-radar-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-radar" /> typcn typcn-radar
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-refresh-outline" /> typcn
                        typcn-refresh-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-refresh" /> typcn
                        typcn-refresh
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-rss-outline" /> typcn
                        typcn-rss-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-rss" /> typcn typcn-rss
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-scissors-outline" /> typcn
                        typcn-scissors-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-scissors" /> typcn
                        typcn-scissors
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-shopping-bag" /> typcn
                        typcn-shopping-bag
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-shopping-cart" /> typcn
                        typcn-shopping-cart
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-at-circular" /> typcn
                        typcn-social-at-circular
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-dribbble-circular" />{" "}
                        typcn typcn-social-dribbble-circular
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-dribbble" /> typcn
                        typcn-social-dribbble
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-facebook-circular" />{" "}
                        typcn typcn-social-facebook-circular
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-facebook" /> typcn
                        typcn-social-facebook
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-flickr-circular" />{" "}
                        typcn typcn-social-flickr-circular
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-flickr" /> typcn
                        typcn-social-flickr
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-github-circular" />{" "}
                        typcn typcn-social-github-circular
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-github" /> typcn
                        typcn-social-github
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-google-plus-circular" />{" "}
                        typcn typcn-social-google-plus-circular
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-google-plus" /> typcn
                        typcn-social-google-plus
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-instagram-circular" />{" "}
                        typcn typcn-social-instagram-circular
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-instagram" /> typcn
                        typcn-social-instagram
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-last-fm-circular" />{" "}
                        typcn typcn-social-last-fm-circular
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-last-fm" /> typcn
                        typcn-social-last-fm
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-linkedin-circular" />{" "}
                        typcn typcn-social-linkedin-circular
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-linkedin" /> typcn
                        typcn-social-linkedin
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-pinterest-circular" />{" "}
                        typcn typcn-social-pinterest-circular
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-pinterest" /> typcn
                        typcn-social-pinterest
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-skype-outline" /> typcn
                        typcn-social-skype-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-skype" /> typcn
                        typcn-social-skype
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-tumbler-circular" />{" "}
                        typcn typcn-social-tumbler-circular
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-tumbler" /> typcn
                        typcn-social-tumbler
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-twitter-circular" />{" "}
                        typcn typcn-social-twitter-circular
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-twitter" /> typcn
                        typcn-social-twitter
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-vimeo-circular" />{" "}
                        typcn typcn-social-vimeo-circular
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-vimeo" /> typcn
                        typcn-social-vimeo
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-youtube-circular" />{" "}
                        typcn typcn-social-youtube-circular
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-social-youtube" /> typcn
                        typcn-social-youtube
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-sort-alphabetically-outline" />{" "}
                        typcn typcn-sort-alphabetically-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-sort-alphabetically" /> typcn
                        typcn-sort-alphabetically
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-sort-numerically-outline" />{" "}
                        typcn typcn-sort-numerically-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-sort-numerically" /> typcn
                        typcn-sort-numerically
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-spanner-outline" /> typcn
                        typcn-spanner-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-spanner" /> typcn
                        typcn-spanner
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-spiral" /> typcn typcn-spiral
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-star-full-outline" /> typcn
                        typcn-star-full-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-star-half-outline" /> typcn
                        typcn-star-half-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-star-half" /> typcn
                        typcn-star-half
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-star-outline" /> typcn
                        typcn-star-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-star" /> typcn typcn-star
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-starburst-outline" /> typcn
                        typcn-starburst-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-starburst" /> typcn
                        typcn-starburst
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-stopwatch" /> typcn
                        typcn-stopwatch
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-support" /> typcn
                        typcn-support
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-tabs-outline" /> typcn
                        typcn-tabs-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-tag" /> typcn typcn-tag
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-tags" /> typcn typcn-tags
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-th-large-outline" /> typcn
                        typcn-th-large-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-th-large" /> typcn
                        typcn-th-large
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-th-list-outline" /> typcn
                        typcn-th-list-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-th-list" /> typcn
                        typcn-th-list
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-th-menu-outline" /> typcn
                        typcn-th-menu-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-th-menu" /> typcn
                        typcn-th-menu
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-th-small-outline" /> typcn
                        typcn-th-small-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-th-small" /> typcn
                        typcn-th-small
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-thermometer" /> typcn
                        typcn-thermometer
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-thumbs-down" /> typcn
                        typcn-thumbs-down
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-thumbs-ok" /> typcn
                        typcn-thumbs-ok
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-thumbs-up" /> typcn
                        typcn-thumbs-up
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-tick-outline" /> typcn
                        typcn-tick-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-tick" /> typcn typcn-tick
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-ticket" /> typcn typcn-ticket
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-time" /> typcn typcn-time
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-times-outline" /> typcn
                        typcn-times-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-times" /> typcn typcn-times
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-trash" /> typcn typcn-trash
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-tree" /> typcn typcn-tree
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-upload-outline" /> typcn
                        typcn-upload-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-upload" /> typcn typcn-upload
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-user-add-outline" /> typcn
                        typcn-user-add-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-user-add" /> typcn
                        typcn-user-add
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-user-delete-outline" /> typcn
                        typcn-user-delete-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-user-delete" /> typcn
                        typcn-user-delete
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-user-outline" /> typcn
                        typcn-user-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-user" /> typcn typcn-user
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-vendor-android" /> typcn
                        typcn-vendor-android
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-vendor-apple" /> typcn
                        typcn-vendor-apple
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-vendor-microsoft" /> typcn
                        typcn-vendor-microsoft
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-video-outline" /> typcn
                        typcn-video-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-video" /> typcn typcn-video
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-volume-down" /> typcn
                        typcn-volume-down
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-volume-mute" /> typcn
                        typcn-volume-mute
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-volume-up" /> typcn
                        typcn-volume-up
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-volume" /> typcn typcn-volume
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-warning-outline" /> typcn
                        typcn-warning-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-warning" /> typcn
                        typcn-warning
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-watch" /> typcn typcn-watch
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-waves-outline" /> typcn
                        typcn-waves-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-waves" /> typcn typcn-waves
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-weather-cloudy" /> typcn
                        typcn-weather-cloudy
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-weather-downpour" /> typcn
                        typcn-weather-downpour
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-weather-night" /> typcn
                        typcn-weather-night
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-weather-partly-sunny" /> typcn
                        typcn-weather-partly-sunny
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-weather-shower" /> typcn
                        typcn-weather-shower
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-weather-snow" /> typcn
                        typcn-weather-snow
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-weather-stormy" /> typcn
                        typcn-weather-stormy
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-weather-sunny" /> typcn
                        typcn-weather-sunny
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-weather-windy-cloudy" /> typcn
                        typcn-weather-windy-cloudy
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-weather-windy" /> typcn
                        typcn-weather-windy
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-wi-fi-outline" /> typcn
                        typcn-wi-fi-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-wi-fi" /> typcn typcn-wi-fi
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-wine" /> typcn typcn-wine
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-world-outline" /> typcn
                        typcn-world-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-world" /> typcn typcn-world
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-zoom-in-outline" /> typcn
                        typcn-zoom-in-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-zoom-in" /> typcn
                        typcn-zoom-in
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-zoom-out-outline" /> typcn
                        typcn-zoom-out-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-zoom-out" /> typcn
                        typcn-zoom-out
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-zoom-outline" /> typcn
                        typcn-zoom-outline
                      </div>
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="typcn typcn-zoom" /> typcn typcn-zoom
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default Icons_typicons;
