import axios from "axios";
import { getAuthToken } from "../utils/auth.utils";
import { BASE_URL } from "../utils/constants";

export function SetApiRequestHeader(customHeader = {}) {
  const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...customHeader,
  };
  return defaultHeaders;
}

export async function updateHeaders(customHeader = {}) {
  const token = getAuthToken();
  if (token) {
    customHeader = { Authorization: "Bearer " + token, ...customHeader };
  }
  const header = SetApiRequestHeader(customHeader);
  instance.defaults.headers = header;
}

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: SetApiRequestHeader(),
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers.Authorization = "Bearer " + `${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const _getApiVersion = (params = {}) => params.apiVersion || "";

export function get(url, params) {
  return instance.get(`${_getApiVersion(params)}/${url}`, {}, params);
}

export function post(url, body, params) {
  return instance.post(`${_getApiVersion(params)}/${url}`, body, params);
}

export function put(url, body, params) {
  return instance.put(`${_getApiVersion(params)}/${url}`, body, params);
}

export function putNew(url, body, params) {
  const instance = axios.create({
    headers: params,
  });
  return instance.put(url, body);
}

export function patch(url, body, params) {
  return instance.patch(`${_getApiVersion(params)}/${url}`, body, params || {});
}

export function remove(url, params) {
  return instance.delete(`${_getApiVersion(params)}/${url}`, {}, params);
}
