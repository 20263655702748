import moment from "moment";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { CSVLink } from "react-csv";
import { ToastContainer, toast } from 'react-toastify';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import AUX from "../../hoc/Aux_";
import { deleteDriver, getDrivers } from "../../store/actions/driver.actions";

const DriversList = (props) => {
  const { loading, drivers, error, getDrivers, deleteDriver } = props;

  const [isModal, toggleModal] = useState(false);
  const [isDeleted, toggleDeleted] = useState(false);
  const [selectedDriver, setDriver] = useState(null);

  useEffect(() => {
    getDrivers();
  }, []);

  const createNotification = () => {
    toast.info("Approved driver cannot be deleted", {
      toastId: "toast-id",
      hideProgressBar: true,
      theme: "colored",
      closeButton: true,
    });
  };

  const headers = [
    { label: "ID", key: "id" },
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },
    { label: "Emergency Contact", key: "emergencyContact" },
    { label: "DOB", key: "dob" },
    { label: "Username", key: "username" },
    { label: "Stripe ID", key: "stripeAccountId" },
    { label: "Address", key: "address" },
    { label: "City", key: "city" },
    { label: "isApproved", key: "isApproved" },
    { label: "Created At", key: "createdAt" },
    { label: "Last Updated At", key: "updatedAt" },
    { label: "License Number", key: "driverProfiles.licenseNumber" },
    { label: "License Version", key: "driverProfiles.licenseVersion" },
    { label: "PSL", key: "driverProfiles.isPsl" },
    { label: "Transport Type", key: "carProfiles[0].transportType" },
    { label: "Transport Model", key: "carProfiles[0].transportModel" },
    { label: "Color", key: "carProfiles[0].color" },
    { label: "Registration Number", key: "carProfiles[0].registrationNumber" },
    { label: "Manufacture Date", key: "carProfiles[0].manufactureDate" },
    { label: "Insurance Number", key: "carProfiles[0].insuranceNumber" },
    { label: "Insurance Company", key: "carProfiles[0].insuranceCompany" },
    { label: "Vehicle Milage", key: "carProfiles[0].vehicleMilage" },
  ];

  return (
    <AUX>
      <div>
        <ToastContainer style={{ ['--toastify-toast-min-height']: "40px" }} />
      </div>
      {loading ? (
        <div id="status">
          <div className="spinner" />
        </div>
      ) : (
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="button-items">
                      <CSVLink
                        data={drivers}
                        headers={headers}
                        filename={"waka-drivers.csv"}
                        className="btn btn-secondary float-right"
                      >
                        <i className="mdi mdi-download" /> Download
                      </CSVLink>
                    </div>
                    <div className="table-responsive">
                      <table
                        id="datatable"
                        className="table table-striped dt-responsive nowrap table-vertical"
                        width="100%"
                        cellSpacing="0"
                      >
                        <thead>
                          <tr>
                            <th>Full Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Emergency Contact</th>
                            <th>City</th>
                            <th>Joining Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {drivers &&
                            drivers.map((item, index) => {
                              return (
                                <tr key={`key-${index}`}>
                                  <td style={{ wordWrap: "break-word", overflowWrap: "break-word", wordBreak: "break-word", width: "15%" }}>{`${item.firstName} ${item.lastName}`}</td>
                                  <td style={{ wordWrap: "break-word", overflowWrap: "break-word", wordBreak: "break-word", width: "25%" }}>{item.email}</td>
                                  <td>{item.phone}</td>
                                  <td>{item.emergencyContact || `N/A`}</td>
                                  <td>{item.city || `N/A`}</td>
                                  <td>
                                    {moment(item.createdAt).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </td>
                                  <td>
                                    {!item.isApproved || !item.isApproved === 1 ? (
                                      <span className="badge badge-danger">
                                        Not Approved
                                      </span>
                                    ) : item.isRideAssigned === 1 ? (
                                      <span className="badge badge-teal">
                                        On Trip
                                      </span>
                                    ) : item.isOnline === 1 ? (
                                      <span className="badge badge-success">
                                        Online
                                      </span>
                                    ) : (
                                      <span className="badge badge-blue-grey">
                                        Offline
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <div className="d-inline-flex">
                                      <Link
                                        to={{
                                          pathname: `/drivers/${item.id}`,
                                          data: item,
                                        }}
                                      >
                                        <a
                                          href="javascript:void(0);"
                                          className="m-r-15 text-muted"
                                        >
                                          <i className="mdi mdi-pencil font-18" />
                                        </a>
                                      </Link>
                                      <Link
                                        onClick={() => {
                                          if (item.isApproved === 1) {
                                            createNotification();
                                          } else {
                                            toggleModal(true);
                                            setDriver(item);
                                          }
                                        }}
                                      >
                                        <a
                                          href="javascript:void(0);"
                                          className="m-r-15 text-muted"
                                        >
                                          <i className="mdi mdi-close font-18" />
                                        </a>
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isModal ? (
        <SweetAlert
          title="Are you sure you want to delete this Driver?"
          success
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            toggleModal(false);
            deleteDriver(selectedDriver);
            toggleDeleted(true);
          }}
          onCancel={() => {
            toggleModal(false);
            setDriver(null);
          }}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}

      {isDeleted ? (
        <SweetAlert
          success
          title={"Driver deleted!"}
          onConfirm={() => toggleDeleted(false)}
        >
          Successfully deleted!
        </SweetAlert>
      ) : null}
    </AUX>
  );
};

const mapStatetoProps = (state) => {
  return {
    loading: state.driver.loading,
    drivers: state.driver.data,
    error: state.driver.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getDrivers: () => dispatch(getDrivers()),
  deleteDriver: (driver) => dispatch(deleteDriver(driver)),
});

export default withRouter(
  connect(
    mapStatetoProps,
    mapDispatchToProps
  )(DriversList)
);
