import Cookies from "js-cookie";
import { TOKEN, AUTH_TOKEN } from "./constants";

export const getToken = () => Cookies.get(TOKEN);
export const setToken = (token) => Cookies.set(TOKEN, token);
export const removeToken = () => Cookies.remove(TOKEN);

export const getAuthToken = () => Cookies.get(AUTH_TOKEN);
export const setAuthToken = (token) => Cookies.set(AUTH_TOKEN, token);
export const removeAuthToken = () => Cookies.remove(AUTH_TOKEN);

export const isLoggedIn = () => !!getToken();
