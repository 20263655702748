import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import { Link } from "react-router-dom";

class Icons_ion extends Component {
  render() {
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <h4 className="mt-0 header-title">Examples</h4>
                    <p className="text-muted m-b-30 font-14">
                      Use <code>&lt;i className="ion-ionic"&gt;&lt;/i&gt;</code>
                      .
                    </p>

                    <div className="row icon-demo-content">
                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ionic" />ion-ionic
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-arrow-up-a" />ion-arrow-up-a
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-arrow-right-a" />ion-arrow-right-a
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-arrow-down-a" />ion-arrow-down-a
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-arrow-left-a" />ion-arrow-left-a
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-arrow-up-b" />ion-arrow-up-b
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-arrow-right-b" />ion-arrow-right-b
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-arrow-down-b" />ion-arrow-down-b
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-arrow-left-b" />ion-arrow-left-b
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-arrow-up-c" />ion-arrow-up-c
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-arrow-right-c" />ion-arrow-right-c
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-arrow-down-c" />ion-arrow-down-c
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-arrow-left-c" />ion-arrow-left-c
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-arrow-return-right" />
                        ion-arrow-return-right
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-arrow-return-left" />
                        ion-arrow-return-left
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-arrow-swap" />ion-arrow-swap
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-arrow-shrink" />ion-arrow-shrink
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-arrow-expand" />ion-arrow-expand
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-arrow-move" />ion-arrow-move
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-arrow-resize" />ion-arrow-resize
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-chevron-up" />ion-chevron-up
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-chevron-right" />ion-chevron-right
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-chevron-down" />ion-chevron-down
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-chevron-left" />ion-chevron-left
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-navicon-round" />ion-navicon-round
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-navicon" />ion-navicon
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-drag" />ion-drag
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-log-in" />ion-log-in
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-log-out" />ion-log-out
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-checkmark-round" />ion-checkmark-round
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-checkmark" />ion-checkmark
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-checkmark-circled" />
                        ion-checkmark-circled
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-close-round" />ion-close-round
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-close" />ion-close
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-close-circled" />ion-close-circled
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-plus-round" />ion-plus-round
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-plus" />ion-plus
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-plus-circled" />ion-plus-circled
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-minus-round" />ion-minus-round
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-minus" />ion-minus
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-minus-circled" />ion-minus-circled
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-information" />ion-information
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-information-circled" />
                        ion-information-circled
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-help" />ion-help
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-help-circled" />ion-help-circled
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-help-buoy" />ion-help-buoy
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-asterisk" />ion-asterisk
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-alert" />ion-alert
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-alert-circled" />ion-alert-circled
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-refresh" />ion-refresh
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-loop" />ion-loop
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-shuffle" />ion-shuffle
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-home" />ion-home
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-search" />ion-search
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-flag" />ion-flag
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-star" />ion-star
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-heart" />ion-heart
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-heart-broken" />ion-heart-broken
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-gear-a" />ion-gear-a
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-gear-b" />ion-gear-b
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-toggle-filled" />ion-toggle-filled
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-toggle" />ion-toggle
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-settings" />ion-settings
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-wrench" />ion-wrench
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-folder" />ion-folder
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-hammer" />ion-hammer
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-edit" />ion-edit
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-trash-a" />ion-trash-a
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-trash-b" />ion-trash-b
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-document" />ion-document
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-document-text" />ion-document-text
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-clipboard" />ion-clipboard
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-scissors" />ion-scissors
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-funnel" />ion-funnel
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-bookmark" />ion-bookmark
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-email" />ion-email
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-folder" />ion-folder
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-filing" />ion-filing
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-archive" />ion-archive
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-reply" />ion-reply
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-reply-all" />ion-reply-all
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-forward" />ion-forward
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-share" />ion-share
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-paper-airplane" />ion-paper-airplane
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-link" />ion-link
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-paperclip" />ion paperclip
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-compose" />ion-compose
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-briefcase" />ion-briefcase
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-medkit" />ion-medkit
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-at" />ion-at
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-pound" />ion-pound
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-quote" />ion-quote
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-cloud" />ion-cloud
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-upload" />ion-upload
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-more" />ion-more
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-grid" />ion-grid
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-calendar" />ion-calendar
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-clock" />ion-clock
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-compass" />ion-compass
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-pinpoint" />ion-pinpoint
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-pin" />ion-pin
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-navigate" />ion-navigate
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-location" />ion-location
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-map" />ion-map
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-model-s" />ion-model-s
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-locked" />ion-locked
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-unlocked" />ion-unlocked
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-key" />ion-key
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-arrow-graph-up-right" />
                        ion-arrow-graph-up-right
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-arrow-graph-down-right" />
                        ion-arrow-graph-down-right
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-arrow-graph-down-left" />
                        ion-arrow-graph-down-left
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-stats-bars" />ion-stats-bars
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-connection-bars" />ion-connection-bars
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-pie-graph" />ion-pie-graph
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-chatbubble" />ion-chatbubble
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-chatbubble-working" />
                        ion-chatbubble-working
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-chatbubbles" />ion-chatbubbles
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-chatbox" />ion-chatbox
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-chatbox-working" />ion-chatbox-working
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-chatboxes" />ion-chatboxes
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-person" />ion-person
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-person-add" />ion-person-add
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-person-stalker" />ion-person-stalker
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-woman" />ion-woman
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-man" />ion-man
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-female" />ion-female
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-male" />ion-male
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-fork" />ion-fork
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-knife" />ion-knife
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-spoon" />ion-spoon
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-beer" />ion-beer
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-wineglass" />ion-wineglass
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-coffee" />ion-coffee
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-icecream" />ion-icecream
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-pizza" />ion-pizza
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-power" />ion-power
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-mouse" />ion-mouse
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-battery-full" />ion-battery-full
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-battery-half" />ion-battery-half
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-battery-low" />ion-battery-low
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-battery-empty" />ion-battery-empty
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-battery-charging" />
                        ion-battery-charging
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-wifi" />ion-wifi
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-bluetooth" />ion-bluetooth
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-calculator" />ion-calculator
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-camera" />ion-camera
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-eye" />ion-eye
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-eye-disabled" />ion-eye-disabled
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-flash" />ion-flash
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-flash-off" />ion-flash-off
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-qr-scanner" />ion-qr-scanner
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-image" />ion-image
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-images" />ion-images
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-contrast" />ion-contrast
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-wand" />ion-wall
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-aperture" />ion-aperture
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-monitor" />ion-monitor
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-laptop" />ion-laptop
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ipad" />ion-ipad
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-iphone" />ion-iphone
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ipod" />ion-ipod
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-printer" />ion-printer
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-usb" />ion-usb
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-outlet" />ion-outlet
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-bug" />ion-bug
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-code" />ion-code
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-code-working" />ion-code-working
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-code-download" />ion-code-download
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-fork-repo" />ion-fork-repo
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-network" />ion-network
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-pull-request" />ion-pull-request
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-merge" />ion-go
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-game-controller-a" />
                        ion-game-controller-a
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-game-controller-b" />
                        ion-game-controller-b
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-xbox" />ion-xbox
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-playstation" />ion-playstation
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-steam" />ion-steam
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-closed-captioning" />
                        ion-closed-captioning
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-videocamera" />ion-camera
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-film-marker" />ion-film-marker
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-disc" />ion-disc
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-headphone" />ion-headphone
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-music-note" />ion-music-note
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-radio-waves" />ion-radio-waves
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-speakerphone" />ion-speakerphone
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-mic-a" />ion-mic-a
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-mic-b" />ion-mic-b
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-mic-c" />ion-mic-c
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-volume-high" />ion-volume-high
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-volume-medium" />ion-volume-medium
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-volume-low" />ion-volume-low
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-volume-mute" />ion-volume-mute
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-levels" />ion-levels
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-play" />ion-play
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-pause" />ion-pause
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-stop" />ion-stop
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-record" />ion-record
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-skip-forward" />ion-skip-forward
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-skip-backward" />ion-skip-backward
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-eject" />ion-eject
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-bag" />ion-bag
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-card" />ion-card
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-cash" />ion-cash
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-pricetag" />ion-pricetag
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-pricetags" />ion-pricetags
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-thumbsup" />ion-thumbsup
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-thumbsdown" />ion-thumbsdown
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-happy" />ion-happy
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-sad" />ion-sad
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-trophy" />ion-trophy
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-podium" />ion-podium
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ribbon-a" />ion-ribbon-a
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ribbon-b" />ion-ribbon-b
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-university" />ion-university
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-magnet" />ion-magnet
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-beaker" />ion-beaker
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-flask" />ion-flask
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-egg" />ion-egg
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-earth" />ion-earth
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-planet" />ion-planet
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-lightbulb" />ion-lightbulb
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-cube" />ion-cube
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-leaf" />ion-leaf
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-waterdrop" />ion-waterdrop
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-flame" />ion-flame
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-fireball" />ion-fireball
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-bonfire" />ion-bonfire
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-umbrella" />ion-umbrella
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-nuclear" />ion-nuclear
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-no-smoking" />ion-no-smoking
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-thermometer" />ion-thermometer
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-speedometer" />ion-speedometer
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-plane" />ion-plane
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-jet" />ion-jet
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-load-a" />ion-load-a
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-load-b" />ion-load-b
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-load-c" />ion-load-c
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-load-d" />ion-load-d
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-ionic-outline" />
                        ion-ios7-ionic-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-arrow-back" />ion-ios7-arrow-back
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-arrow-forward" />
                        ion-ios7-arrow-forward
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-arrow-up" />ion-ios7-arrow-up
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-arrow-right" />
                        ion-ios7-arrow-right
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-arrow-down" />ion-ios7-arrow-down
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-arrow-left" />ion-ios7-arrow-left
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-arrow-thin-up" />
                        ion-ios7-arrow-thin-up
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-arrow-thin-right" />
                        ion-ios7-arrow-thin-right
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-arrow-thin-down" />
                        ion-ios7-arrow-thin-down
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-arrow-thin-left" />
                        ion-ios7-arrow-thin-left
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-circle-filled" />
                        ion-ios7-circle-filled
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-circle-outline" />
                        ion-ios7-circle-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-checkmark-empty" />
                        ion-ios7-checkmark-empty
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-checkmark-outline" />
                        ion-ios7-checkmark-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-checkmark" />ion-ios7-checkmark
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-plus-empty" />ion-ios7-plus-empty
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-plus-outline" />
                        ion-ios7-plus-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-plus" />ion-ios7 more
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-close-empty" />
                        ion-ios7-close-empty
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-close-outline" />
                        ion-ios7-close-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-close" />ion-ios7-close
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-minus-empty" />
                        ion-ios7-minus-empty
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-minus-outline" />
                        ion-ios7-minus-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-minus" />ion-ios7-minus
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-information-empty" />
                        ion-ios7-information-empty
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-information-outline" />
                        ion-ios7-information-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-information" />
                        ion-ios7-information
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-help-empty" />ion-ios7-help-empty
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-help-outline" />
                        ion-ios7-help-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-help" />ion-ios7-help
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-search" />ion-ios7-search
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-search-strong" />
                        ion-ios7-search-strong
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-star" />ion-ios7-star
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-star-half" />ion-ios7-star-half
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-star-outline" />
                        ion-ios7-star-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-heart" />ion-ios7-heart
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-heart-outline" />
                        ion-ios7-heart-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-more" />ion-ios7-more
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-more-outline" />
                        ion-ios7-more-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-home" />ion-ios7-home
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-home-outline" />
                        ion-ios7-home-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-cloud" />ion-ios7-cloud
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-cloud-outline" />
                        ion-ios7-cloud-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-cloud-upload" />
                        ion-ios7-cloud-upload
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-cloud-upload-outline" />
                        ion-ios7-cloud-upload-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-cloud-download" />
                        ion-ios7-cloud-download
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-cloud-download-outline" />
                        ion-ios7-cloud-download-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-upload" />ion-ios7-upload
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-upload-outline" />
                        ion-ios7-upload-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-download" />ion-ios7-download
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-download-outline" />
                        ion-ios7-download-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-refresh" />ion-ios7-refresh
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-refresh-outline" />
                        ion-ios7-refresh-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-refresh-empty" />
                        ion-ios7-refresh-empty
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-reload" />ion-ios7-reload
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-loop-strong" />
                        ion-ios7-loop-strong
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-loop" />ion-ios7-loop
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-bookmarks" />ion-ios7-bookmarks
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-bookmarks-outline" />
                        ion-ios7-bookmarks-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-flag" />ion-ios7-flag
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-flag-outline" />
                        ion-ios7-flag-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-glasses" />ion-ios7-glasses
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-glasses-outline" />
                        ion-ios7-glasses-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-browsers" />ion-ios7-browsers
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-browsers-outline" />
                        ion-ios7-browsers-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-at" />ion-ios7-at
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-at-outline" />ion-ios7-at-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-cart" />ion-ios7-cart
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-cart-outline" />
                        ion-ios7-cart-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-pricetag" />ion-ios7-pricetag
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-pricetag-outline" />
                        ion-ios7-pricetag-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-pricetags" />ion-ios7-pricetags
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-pricetags-outline" />
                        ion-ios7-pricetags-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-chatboxes" />ion-ios7-chatboxes
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-chatboxes-outline" />
                        ion-ios7-chatboxes-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-chatbubble" />ion-ios7-chatbubble
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-chatbubble-outline" />
                        ion-ios7-chatbubble-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-cog" />ion-ios7-cog
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-cog-outline" />
                        ion-ios7-cog-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-gear" />ion-ios7-gear
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-gear-outline" />
                        ion-ios7-gear-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-settings" />ion-ios7-settings
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-settings-strong" />
                        ion-ios7-settings-strong
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-toggle" />ion-ios7-toggle
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-toggle-outline" />
                        ion-ios7-toggle-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-analytics" />ion-ios7-analytics
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-analytics-outline" />
                        ion-ios7-analytics-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-pie" />ion-ios7-pie
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-pie-outline" />
                        ion-ios7-pie-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-pulse" />ion-ios7-pulse
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-pulse-strong" />
                        ion-ios7-pulse-strong
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-filing" />ion-ios7-filing
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-filing-outline" />
                        ion-ios7-filing-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-box" />ion-ios7-box
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-box-outline" />
                        ion-ios7-box-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-compose" />ion-ios7-compose
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-compose-outline" />
                        ion-ios7-compose-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-trash" />ion-ios7-trash
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-trash-outline" />
                        ion-ios7-trash-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-copy" />ion-ios7-copy
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-copy-outline" />
                        ion-ios7-copy-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-email" />ion-ios7-email
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-email-outline" />
                        ion-ios7-email-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-undo" />ion-ios7-undo
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-undo-outline" />
                        ion-ios7-undo-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-redo" />ion ios7-ready
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-redo-outline" />
                        ion-ios7-redo-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-redo-outline" />
                        ion-ios7-redo-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-paperplane" />ion-ios7-paperplane
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-paperplane-outline" />
                        ion-ios7-paperplane-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-folder" />ion-ios7-folder
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-folder-outline" />
                        ion-ios7-folder-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-paper" />ion-ios7-paper
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-paper-outline" />
                        ion-ios7-paper-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-world" />ion-ios7-world
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-world-outline" />
                        ion-ios7-world-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-alarm" />ion-ios7-alarm
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-alarm-outline" />
                        ion-ios7-alarm-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-speedometer" />
                        ion-ios7-speedometer
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-speedometer-outline" />
                        ion-ios7-speedometer-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-stopwatch" />ion-ios7-stopwatch
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-stopwatch-outline" />
                        ion-ios7-stopwatch-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-timer" />ion-ios7-timer
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-timer-outline" />
                        ion-ios7-timer-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-clock" />ion-ios7-clock
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-clock-outline" />
                        ion-ios7-clock-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-time" />ion-ios7-time
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-time-outline" />
                        ion-ios7-time-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-calendar" />ion-ios7-calendar
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-calendar-outline" />
                        ion-ios7-calendar-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-photos" />ion-ios7-photos
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-photos-outline" />
                        ion-ios7-photos-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-albums" />ion-ios7-albums
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-albums-outline" />
                        ion-ios7-albums-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-camera" />ion-ios7-camera
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-camera-outline" />
                        ion-ios7-camera-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-reverse-camera" />
                        ion-ios7-reverse-camera
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-reverse-camera-outline" />
                        ion-ios7-reverse-camera-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-eye" />ion-ios7-eye
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-eye-outline" />
                        ion-ios7-eye-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-bolt" />ion-ios7-bolt
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-bolt-outline" />
                        ion-ios7-bolt-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-barcode" />ion-ios7-barcode
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-barcode-outline" />
                        ion-ios7-barcode-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-briefcase" />ion-ios7-briefcase
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-briefcase-outline" />
                        ion-ios7-briefcase-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-medkit" />ion-ios7-medkit
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-medkit-outline" />
                        ion-ios7-medkit-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-infinite" />ion-ios7-infinite
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-infinite-outline" />
                        ion-ios7-infinite-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-calculator" />ion-ios7-calculator
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-calculator-outline" />
                        ion-ios7-calculator-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-keypad" />ion-ios7-keypad
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-keypad-outline" />
                        ion-ios7-keypad-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-telephone" />ion-ios7-telephone
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-telephone-outline" />
                        ion-ios7-telephone-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-drag" />ion-ios7-drag
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-location" />ion-ios7-location
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-location-outline" />
                        ion-ios7-location-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-navigate" />ion-ios7-navigate
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-navigate-outline" />
                        ion-ios7-navigate-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-locked" />ion-ios7-locked
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-locked-outline" />
                        ion-ios7-locked-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-unlocked" />ion-ios7-unlocked
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-unlocked-outline" />
                        ion-ios7-unlocked-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-monitor" />ion-ios7-monitor
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-monitor-outline" />
                        ion-ios7-monitor-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-printer" />ion-ios7-printer
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-printer-outline" />
                        ion-ios7-printer-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-person" />ion-ios7-person
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-person-outline" />
                        ion-ios7-person-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-personadd" />ion-ios7-personadd
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-personadd-outline" />
                        ion-ios7-personadd-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-people" />ion-ios7-people
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-people-outline" />
                        ion-ios7-people-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-tennisball" />ion ios7-tennis
                        ball
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-tennisball-outline" />ion ios7
                        tennis ball outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-baseball" />ion-ios7-baseball
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-baseball-outline" />
                        ion-ios7-baseball-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-basketball" />ion-ios7-basketball
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-basketball-outline" />
                        ion-ios7-basketball-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-football" />ion-ios7-football
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-football-outline" />
                        ion-ios7-football-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-americanfootball" />
                        ion-ios7-americanfootball
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-americanfootball-outline" />
                        ion-ios7-americanfootball-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-paw" />ion-ios7-paw
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-paw-outline" />
                        ion-ios7-paw-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-sunny" />ion-ios7-sunny
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-sunny-outline" />
                        ion-ios7-sunny-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-partlysunny" />
                        ion-ios7-partlysunny
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-partlysunny-outline" />
                        ion-ios7-partlysunny-Outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-cloudy" />ion-ios7-cloudy
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-cloudy-outline" />
                        ion-ios7-cloudy-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-rainy" />ion-ios7-rainy
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-rainy-outline" />
                        ion-ios7-rainy-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-thunderstorm" />
                        ion-ios7-thunderstorm
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-thunderstorm-outline" />
                        ion-ios7-thunderstorm-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-snowy" />ion-ios7-snowy
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-moon" />ion-ios7-moon
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-moon-outline" />
                        ion-ios7-moon-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-cloudy-night" />
                        ion-ios7-cloudy-night
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-cloudy-night-outline" />
                        ion-ios7-cloudy-night-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-musical-notes" />
                        ion-ios7-musical-notes
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-musical-note" />
                        ion-ios7-musical-note
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-bell" />ion-ios7-bell
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-bell-outline" />
                        ion-ios7-bell-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-mic" />ion-ios7-small
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-mic-outline" />
                        ion-ios7-mic-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-mic-off" />ion-ios7-mic-off
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-volume-high" />
                        ion-ios7-volume-high
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-volume-low" />ion-ios7-volume-low
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-play" />ion-ios7-play
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-play-outline" />
                        ion-ios7-play-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-pause" />ion-ios7-pause
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-pause-outline" />
                        ion-ios7-pause-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-recording" />ion-ios7-recording
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-recording-outline" />
                        ion-ios7-recording-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-fastforward" />
                        ion-iOS7-FastForward
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-fastforward-outline" />
                        ion-iOS7-FastForward-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-rewind" />ion-ios7-rewind
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-rewind-outline" />
                        ion-ios7-rewind-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-skipbackward" />
                        ion-ios7-skipbackward
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-skipbackward-outline" />
                        ion-ios7-skipbackward-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-skipforward" />
                        ion-ios7-skipforward
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-skipforward-outline" />
                        ion-ios7-skipforward-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-videocam" />ion-iOS7-videocam
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-videocam-outline" />
                        ion-ios7-videocam-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-film" />ion-ios7-film
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-film-outline" />
                        ion-ios7-film-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-lightbulb" />ion-ios7-lightbulb
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-lightbulb-outline" />
                        ion-ios7-lightbulb-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-wineglass" />ion-ios7-wineglass
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-ios7-wineglass-outline" />
                        ion-ios7-wineglass-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-system-back" />
                        ion-android-system-back
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-system-home" />
                        ion-android-system-home
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-system-windows" />
                        ion-android-system-windows
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-wifi" />ion-android-wifi
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-battery" />ion-android-battery
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-storage" />ion-android-storage
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-keypad" />ion-android-keypad
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-call" />ion-android-call
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-arrow-back" />
                        ion-android-arrow-back
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-arrow-forward" />
                        ion-android-arrow-forward
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-arrow-up-right" />
                        ion-android-arrow-up-right
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-arrow-down-right" />
                        ion-android-arrow-down-right
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-arrow-down-left" />
                        ion-android-arrow-down-left
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-arrow-up-left" />
                        ion-android-arrow-up-left
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-sort" />ion-android-sort
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-drawer" />ion-android-drawer
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-stair-drawer" />
                        ion-android-stair-drawer
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-more" />ion-android-more
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-dropdown" />
                        ion-android-dropdown
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-settings" />
                        ion-android-settings
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-mixer" />ion-android-mixer
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-display" />ion-android-display
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-star" />ion-android-star
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-search" />ion-android-search
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-microphone" />
                        ion-android-microphone
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-checkmark" />
                        ion-android-checkmark
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-information" />
                        ion-android-information
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-add" />ion-android-add
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-remove" />ion-android-remove
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-close" />ion-android-close
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-trash" />ion-android-trash
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-volume" />ion-android-volume
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-printer" />ion-android-printer
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-download" />
                        ion-android-download
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-hand" />ion-android-hand
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-developer" />
                        ion-android-developer
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-calendar" />
                        ion-android-calendar
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-clock" />ion-android-clock
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-alarm" />ion-android-alarm
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-stopwatch" />
                        ion-android-stopwatch
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-data" />ion-android-data
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-timer" />ion-android-timer
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-camera" />ion-android-camera
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-image" />ion-android-image
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-location" />
                        ion-android-location
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-locate" />ion-android-locate
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-earth" />ion-android-earth
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-book" />ion-android-book
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-note" />ion-android-note
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-promotion" />
                        ion-android-promotion
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-playstore" />
                        ion-android-playstore
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-share" />ion-android-share
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-send" />ion-android-send
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-mail" />ion-android-mail
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-inbox" />ion-android-inbox
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-archive" />ion-android-archive
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-folder" />ion-android-folder
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-forums" />ion-android-forums
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-chat" />ion-android-chat
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-user-menu" />
                        ion-android-user-menu
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-contact" />ion-android-contact
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-contacts" />
                        ion-android-contacts
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-friends" />ion-android-friends
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-add-contact" />
                        ion-android-add-contact
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-social-user" />
                        ion-android-social-user
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-social" />ion-android-social
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-reminder" />
                        ion-android-reminder
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-android-lightbulb" />
                        ion-android-lightbulb
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-twitter" />ion-social-twitter
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-twitter-outline" />
                        ion-social-twitter-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-facebook" />ion-social-facebook
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-facebook-outline" />
                        ion-social-facebook-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-googleplus" />
                        ion-social-googleplus
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-googleplus-outline" />
                        ion-social-googleplus-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-google" />ion-social-google
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-google-outline" />
                        ion-social-google-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-dribbble" />ion-social-dribbble
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-dribbble-outline" />
                        ion-social-dribbble-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-github" />ion-social-github
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-github-outline" />
                        ion-social-github-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-instagram" />
                        ion-social-instagram
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-instagram-outline" />
                        ion-social-instagram-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-foursquare" />
                        ion-social-foursquare
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-foursquare-outline" />
                        ion-social-foursquare-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-pinterest" />
                        ion-social-pinterest
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-pinterest-outline" />
                        ion-social-pinterest-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-rss" />ion-social-rss
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-rss-outline" />
                        ion-social-rss-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-tumblr" />ion-social-tumblr
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-tumblr-outline" />
                        ion-social-tumblr-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-wordpress" />
                        ion-social-wordpress
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-wordpress-outline" />
                        ion-social-wordpress-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-reddit" />ion-social-reddit
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-reddit-outline" />
                        ion-social-reddit-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-hackernews" />
                        ion-social-hackernews
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-hackernews-outline" />
                        ion-social-hackernews-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-designernews" />
                        ion-social-designernews
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-designernews-outline" />
                        ion-social-designernews-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-yahoo" />ion-social-yahoo
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-yahoo-outline" />
                        ion-social-yahoo-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-buffer" />ion-social-buffer
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-buffer-outline" />
                        ion-social-buffer-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-skype" />ion-social-skype
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-skype-outline" />
                        ion-social-skype-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-linkedin" />ion-social-linkedin
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-linkedin-outline" />
                        ion-social-linkedin-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-usd" />ion-social-usd
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-usd-outline" />
                        ion-social-usd-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-bitcoin" />ion-social-bitcoin
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-bitcoin-outline" />
                        ion-social-bitcoin-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-vimeo" />ion-social-vimeo
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-vimeo-outline" />
                        ion-social-vimeo-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-youtube" />ion-social-youtube
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-youtube-outline" />
                        ion-social-youtube-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-dropbox" />ion-social-dropbox
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-dropbox-outline" />
                        ion-social-dropbox-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-apple" />ion-social-apple
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-apple-outline" />
                        ion-social-apple-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-android" />ion-social-android
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-android" />
                        ion-social-android-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-windows" />ion-social-windows
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-windows-outline" />
                        ion-social-windows-outline
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-tux" />ion-social-tux
                      </div>

                      <div className="col-xl-3 col-md-4 col-sm-6">
                        <i className="ion-social-freebsd-devil" />
                        ion-social-freebsd-devil
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default Icons_ion;
