import React, { Component } from "react";
import aux from "../../hoc/Aux_";

class NotificationsPage extends Component {
  render() {
    return (
      <aux>
        <div class="page-content-wrapper">
          <div class="container-fluid" />
          <h3 align="center">Coming Soon...</h3>
        </div>
      </aux>
    );
  }
}

export default NotificationsPage;
