import moment from "moment";
import React, { useEffect } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AUX from "../../hoc/Aux_";
import { getRides } from "../../store/actions/ride.actions";

const RidesList = (props) => {
  const { loading, rides, error, getRides } = props;

  useEffect(() => {
    getRides();
  }, []);

  const formatTime = (timeStr) => {
    return moment(timeStr).fromNow();
  };

  const mapRideStatus = (rideStatus, paymentStatus = null) => {
    switch (rideStatus) {
      case "posted":
        return <span className="badge badge-default">Searching Driver</span>;
      case "assigned":
        return <span className="badge badge-info">Assigned</span>;
      case "unassigned":
        return <span className="badge badge-info">Driver Not Found</span>;
      case "arrivedAtLocation":
        return (
          <span className="badge badge-blue-grey">
            Driver at pickup Location
          </span>
        );
      case "started":
        return <span className="badge badge-success">On Trip</span>;
      case "cancelled":
        return <span className="badge badge-danger">Cancelled</span>;
      case "completed":
        return (
          <AUX>
            <span className="badge badge-teal">Completed</span>
            <br />
            {paymentStatus && paymentStatus === 1 ? (
              <span className="badge badge-teal">Payment Succeed</span>
            ) : (
              <span className="badge badge-danger">Payment Failed</span>
            )}
          </AUX>
        );

      default:
        break;
    }
  };

  const headers = [
    { label: "Ride ID", key: "id" },
    { label: "Dropoff Address", key: "dropOffAddress" },
    { label: "Dropoff Latitude", key: "dropoffAddress.location.lat" },
    { label: "Dropoff Longitude", key: "dropoffAddress.location.lng" },
    { label: "Pickup Address", key: "pickupAddress" },
    { label: "Pickup Latitude", key: "pickupAddress.location.lat" },
    { label: "Pickup Longitude", key: "pickupAddress.location.lng" },
    { label: "Started At", key: "startedAt" },
    { label: "Ended At", key: "endedAt" },
    { label: "Status", key: "rideStatus" },
    { label: "Total Bill", key: "bill?.total" },
    { label: "Total Tip", key: "tipAmount" },
    { label: "Tip Status", key: "tipStatus" },
    { label: "Distance", key: "bill?.distance" },
    { label: "Duration", key: "bill?.duration" },
    { label: "Payment Status", key: "paymentStatus" },
    { label: "Stripe Payment Method ID", key: "paymentMethodId" },
    { label: "Ride OTP", key: "rideOtp" },
    { label: "Car Type", key: "carType.carTypeName" },
    { label: "Rider ID", key: "customer.id" },
    { label: "Rating By Rider", key: "ratingByRider" },
    { label: "Rating By Driver", key: "ratingByDriver" },
    { label: "Rider Name", key: "customer.fullName" },
    { label: "Rider Email", key: "customer.email" },
    { label: "Rider Phone", key: "customer.phone" },
    { label: "Driver ID", key: "driver.id" },
    { label: "Driver First Name", key: "driver.firstname" },
    { label: "Driver Last Name", key: "driver.lastname" },
    { label: "Driver Email", key: "driver.email" },
    { label: "Driver Phone", key: "driver.phone" },
    { label: "Created At", key: "createdAt" },
    { label: "Last Updated At", key: "updatedAt" },
  ];

  return (
    <AUX>
      <div>
        <ToastContainer style={{ ['--toastify-toast-min-height']: "40px" }} />
      </div>
      {loading ? (
        <div id="status">
          <div className="spinner" />
        </div>
      ) : (
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="button-items">
                      <CSVLink
                        data={rides}
                        headers={headers}
                        filename={"waka-rides.csv"}
                        className="btn btn-secondary float-right"
                      >
                        <i className="mdi mdi-download" /> Download
                      </CSVLink>
                    </div>
                    <div className="table-responsive">
                      <table
                        id="datatable"
                        className="table table-striped dt-responsive nowrap table-vertical"
                        width="100%"
                        cellSpacing="0"
                      >
                        <thead>
                          <tr>
                            <th>Ride ID</th>
                            <th>Pickup Address</th>
                            <th>Dropoff Address</th>
                            <th>Car Type</th>
                            <th>Customer Email/Phone</th>
                            <th>Driver Email/Phone</th>
                            <th>Created</th>
                            <th>Created at</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rides &&
                            rides.map((item, index) => {
                              return (
                                <tr key={`key-${index}`}>
                                  <td style={{wordWrap: "break-word", overflowWrap: "break-word", wordBreak: "break-word", width: "15%"}}>{item.id}</td>
                                  <td style={{wordWrap: "break-word", overflowWrap: "break-word", wordBreak: "break-word", width: "25%"}}>
                                    {JSON.parse(item.pickupAddress).address || `N/A`}
                                  </td>
                                  <td style={{wordWrap: "break-word", overflowWrap: "break-word", wordBreak: "break-word", width: "25%"}}>
                                    {JSON.parse(item.dropOffAddress).address || `N/A`}
                                  </td>
                                  <td>
                                    {(item.Driver && item.Driver.Cars && item.Driver.Cars.carType) ||
                                      `N/A`}
                                  </td>
                                  <td style={{wordWrap: "break-word", overflowWrap: "break-word", wordBreak: "break-word", width: "20%"}}>
                                    {(item.Customer && item.Customer.email) ||
                                      `N/A`}{" "}
                                    <br />
                                    {(item.Customer && item.Customer.phone) ||
                                      `N/A`}
                                  </td>
                                  <td style={{wordWrap: "break-word", overflowWrap: "break-word", wordBreak: "break-word", width: "20%"}}>
                                    {(item.Driver && item.Driver.Driverdetail && item.Driver.Driverdetail.email) || `N/A`}{" "}
                                    <br />
                                    {(item.Driver && item.Driver.Driverdetail && item.Driver.Driverdetail.phone) || `N/A`}
                                  </td>
                                  <td>{formatTime(item.createdAt)}</td>
                                  <td>
                                    {moment(item.createdAt).format(
                                      "DD-MM-YYYY HH:mm:ss"
                                    )}
                                  </td>
                                  <td>
                                    {mapRideStatus(
                                      item.rideStatus,
                                      item.paymentStatus
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </AUX>
  );
};

const mapStatetoProps = (state) => {
  return {
    loading: state.ride.loading,
    rides: state.ride.data,
    error: state.ride.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getRides: () => dispatch(getRides()),
});

export default withRouter(
  connect(
    mapStatetoProps,
    mapDispatchToProps
  )(RidesList)
);
