import React, { Component } from "react";
import AUX from "../../../hoc/Aux_";
import { Link } from "react-router-dom";

class Ui_colors extends Component {
  render() {
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-md-4">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="color-box bg-primary">#67a8e4</div>
                    <h5 className="mb-0 m-t-20 text-primary text-center font-18">
                      Primary
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-4">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="color-box bg-success">#4ac18e</div>
                    <h5 className="mb-0 m-t-20 text-success text-center font-18">
                      Success
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-4">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="color-box bg-info">#3bc3e9</div>
                    <h5 className="mb-0 m-t-20 text-info text-center font-18">
                      Info
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-4">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="color-box bg-warning">#ffbb44</div>
                    <h5 className="mb-0 m-t-20 text-warning text-center font-18">
                      Warning
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-4">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="color-box bg-danger">#ea553d</div>
                    <h5 className="mb-0 m-t-20 text-danger text-center font-18">
                      Danger
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-4">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="color-box bg-dark">#1d1e3a</div>
                    <h5 className="mb-0 m-t-20 text-dark text-center font-18">
                      Dark
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-4">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="color-box bg-pink">#f06292</div>
                    <h5 className="mb-0 m-t-20 text-pink text-center font-18">
                      Pink
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-4">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="color-box bg-purple">#6d60b0</div>
                    <h5 className="mb-0 m-t-20 text-purple text-center font-18">
                      Purple
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-4">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="color-box bg-indigo">#5468da</div>
                    <h5 className="mb-0 m-t-20 text-indigo text-center font-18">
                      Indigo
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-4">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="color-box bg-teal">#009688</div>
                    <h5 className="mb-0 m-t-20 text-teal text-center font-18">
                      Teal
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-4">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="color-box bg-lime">#afb42b</div>
                    <h5 className="mb-0 m-t-20 text-lime text-center font-18">
                      Lime
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-4">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="color-box bg-orange">#fb8c00</div>
                    <h5 className="mb-0 m-t-20 text-orange text-center font-18">
                      Orange
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-4">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="color-box bg-brown">#8d6e63</div>
                    <h5 className="mb-0 m-t-20 text-brown text-center font-18">
                      Brown
                    </h5>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-md-4">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="color-box bg-blue-grey">#90a4ae</div>
                    <h5 className="mb-0 m-t-20 text-blue-grey text-center font-18">
                      Blue Grey
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default Ui_colors;
