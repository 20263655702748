const { get, post, remove, put, putNew } = require("./http.service");

const DataService = {
  login: (userData) => post(`user/adminLogin`, userData),
  getCustomers: () => get(`user/CUSTOMER`),
  getCustomerById: (id) => get(`user/getUserById/${id}/CUSTOMER`),
  getDrivers: () => get(`user/DRIVER`),
  getDriverById: (id) => get(`user/getUserById/${id}/DRIVER`),
  getRides: () => get(`ride/getAllRides`),
  getConfigurations: () => get(`config/getAllConfigs`),
  updateConfigurations: (body) => put(`config/updateConfig`, body),
  getCarTypes: () => get(`CarTypes`),
  updateCarDetails: (body) => put(`car/updateCarDetails`, body),
  approveDriver: (body) => put(`user/isDriverApproved`, body),
  getAds: () => get(`adds/getAddsList`),
  updateAd: (body) => put(`adds/updateAdds`, body),
  uploadAd: (body) => post(`fileUpload/addfile`, body),
  checkFileUpload: (type, url, body) => putNew(url, body, { "Content-Type": type, "x-amz-acl": "public-read"}),
  getUploadedFile: (body) => post(`fileUpload/getFile`, body),
  uploadFile: (body) =>
    post(`Attachments/images/upload`, body, {
      headers: { "Content-Type": "multipart/form-data" },
    }),
  updateCustomer: (body) => put(`user/updateUser`, body),
  deleteDriver: (id) => remove(`user/deleteUser/${id}`),
  deleteCustomer: (id) => remove(`user/deleteUser/${id}`),
  deleteDriverProfile: (id) => remove(`DriverProfiles/${id}`),
  deleteCarProfile: (id) => remove(`CarProfiles/${id}`),
  getAllUploadedDocument: (id) => get(`document/getDocumentByEntityId/${id}`),
  updateStripeAcc: (body) => put(`driver/updateDriverDetails`, body),
  resendVerificationMail: (body) => post(`user/resendVerificationMail`, body),
  UNAUTHORIZED: 401
};

export default DataService;
