import { toast } from "react-toastify";
import DataService from "../../services/data.service";
import { throwError, to } from "../../utils/utils";
import ACTIONS from "./types.actions";
import { logout } from "./user.actions";

export function customerRequest() {
  return { type: ACTIONS.CUSTOMER_REQUEST };
}

export function customerSuccess(response) {
  return { type: ACTIONS.CUSTOMER_SUCCESS, response };
}

export function customerDetailSuccess(response) {
  return { type: ACTIONS.CUSTOMER_DETAIL_SUCCESS, response };
}

export function customerError(error) {
  return { type: ACTIONS.CUSTOMER_ERROR, error };
}

export function getCustomers() {
  return async (dispatch) => {
    dispatch(customerRequest());
    try {
      const [err, resp] = await to(DataService.getCustomers());
      if (err) {
        const {status} = err.response;
        if(status === DataService.UNAUTHORIZED) {
          toast.error("Unauthorized or session timed out. Kindly relogin", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        } else {
          toast.error("Something went wrong", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        }
        throwError(err);
      }
      dispatch(customerSuccess(resp.data.user));
      return;
    } catch (error) {
      dispatch(customerError(error));
      throwError(error);
    }
  };
}

export function getCustomerById(id) {
  return async (dispatch) => {
    dispatch(customerRequest());
    try {
      const [err, resp] = await to(DataService.getCustomerById(id));
      if (err) {
        const {status} = err.response;
        if(status === DataService.UNAUTHORIZED) {
          toast.error("Unauthorized or session timed out. Kindly relogin", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        } else {
          toast.error("Something went wrong", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        }
        throwError(err);
      }
      dispatch(customerDetailSuccess(resp.data.user));
      return;
    } catch (error) {
      dispatch(customerError(error));
      throwError(error);
    }
  };
}

export function updateCustomer(body) {
  return async (dispatch) => {
    dispatch(customerRequest());
    try {
      const [err, resp] = await to(DataService.updateCustomer(body));
      if (err) {
        const {status} = err.response;
        if(status === DataService.UNAUTHORIZED) {
          toast.error("Unauthorized or session timed out. Kindly relogin", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        } else {
          toast.error("Something went wrong", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        }
        throwError(err);
      }
      dispatch(customerDetailSuccess(resp.data));
      if(resp.data && resp.data.success) {
        toast.success("Customer updated successfully!", {
          hideProgressBar: true,
          theme: "colored",
          closeButton: true,
          delay: 200
        });
      } else {
        toast.error(resp.data.message, {
          hideProgressBar: true,
          theme: "colored",
          closeButton: true,
          delay: 200
        });
      }
      return;
    } catch (error) {
      dispatch(customerError(error));
      throwError(error);
    }
  };
}

export function deleteCustomer(cust) {
  return async (dispatch) => {
    dispatch(customerRequest());
    try {
      if (!cust) {
        throwError("");
      }
      const [err, resp] = await to(DataService.deleteCustomer(cust.id));
      if (err) {
        const {status} = err.response;
        if(status === DataService.UNAUTHORIZED) {
          toast.error("Unauthorized or session timed out. Kindly relogin", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        } else {
          toast.error("Something went wrong", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        }
        throwError(err);
      }
      return dispatch(getCustomers());
    } catch (error) {
      dispatch(customerError(error));
      throwError(error);
    }
  };
}

export function sendVerificationMail(body) {
  return async (dispatch) => {
    dispatch(customerRequest());
    try {
      const [err, resp] = await to(DataService.resendVerificationMail(body));
      if (err) {
        const {status} = err.response;
        if(status === DataService.UNAUTHORIZED) {
          toast.error("Unauthorized or session timed out. Kindly relogin", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        } else {
          toast.error("Something went wrong", {
            hideProgressBar: true,
            theme: "colored",
            closeButton: true,
          });
        }
        throwError(err);
      } else {
        toast.success(resp.data.message, {
          hideProgressBar: true,
          theme: "colored",
          closeButton: true,
          delay: 200
        });
      }
      dispatch(getCustomerById(body.id))
      return;
    } catch (error) {
      dispatch(customerError(error));
      throwError(error);
    }
  };
}
