import moment from "moment";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AUX from "../../hoc/Aux_";

import {
  deleteCustomer,
  getCustomers
} from "../../store/actions/customer.actions";
import { isLoggedIn } from "../../utils/auth.utils";

const CustomersList = (props) => {
  const { loading, customers, error, getCustomers, deleteCustomer } = props;

  const [isModal, toggleModal] = useState(false);
  const [isDeleted, toggleDeleted] = useState(false);
  const [selectedCustomer, setCustomer] = useState(null);

  useEffect(() => {
    if (isLoggedIn()) getCustomers();
  }, []);

  const headers = [
    { label: "ID", key: "id" },
    { label: "Full Name", key: "firstName" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "phone" },
    { label: "Emergency Contact", key: "emergencyContact" },
    { label: "Username", key: "username" },
    { label: "Email Verified", key: "emailVerified" },
    { label: "Stripe ID", key: "stripeCustomerId" },
    { label: "Created At", key: "createdAt" },
    { label: "Last Updated At", key: "updatedAt" },
  ];

  return (
    <AUX>
      <div>
        <ToastContainer style={{ ['--toastify-toast-min-height']: "40px" }} />
      </div>
      {loading ? (
        <div id="status">
          <div className="spinner" />
        </div>
      ) : (
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="button-items">
                      <CSVLink
                        data={customers}
                        headers={headers}
                        filename={"waka-customers.csv"}
                        className="btn btn-secondary float-right"
                      >
                        <i className="mdi mdi-download" /> Download
                      </CSVLink>
                    </div>
                    <div className="table-responsive">
                      <table
                        id="datatable"
                        className="table table-striped dt-responsive nowrap table-vertical"
                        width="100%"
                        cellSpacing="0"
                      >
                        <thead>
                          <tr>
                            <th>Full Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Emergency Contact No</th>
                            <th>Joining Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customers &&
                            customers.map((cust, index) => {
                              return (
                                <tr key={`key-${index}`}>
                                  <td style={{ wordWrap: "break-word", overflowWrap: "break-word", wordBreak: "break-word", width: "20%" }}>{cust.firstName} {cust.lastName}</td>
                                  <td style={{ wordWrap: "break-word", overflowWrap: "break-word", wordBreak: "break-word", width: "30%" }}>{cust.email}</td>
                                  <td>{cust.phone}</td>
                                  <td>{cust.emergencyContact || `N/A`}</td>
                                  <td>
                                    {moment(cust.createdAt).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </td>
                                  <td>
                                    <div className="d-inline-flex">
                                      <Link
                                        to={{
                                          pathname: `/customers/${cust.id}`,
                                          data: cust,
                                        }}
                                      >
                                        <a
                                          href="javascript:void(0);"
                                          className="m-r-15 text-muted"
                                        >
                                          <i className="mdi mdi-pencil font-18" />
                                        </a>
                                      </Link>
                                      <Link
                                        onClick={() => {
                                          toggleModal(true);
                                          setCustomer(cust);
                                        }}
                                      >
                                        <a
                                          href="javascript:void(0);"
                                          className="m-r-15 text-muted"
                                        >
                                          <i className="mdi mdi-close font-18" />
                                        </a>
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isModal ? (
        <SweetAlert
          title="Are you sure you want to delete this Customer?"
          success
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            toggleModal(false);
            deleteCustomer(selectedCustomer);
            toggleDeleted(true);
          }}
          onCancel={() => {
            toggleModal(false);
            setCustomer(null);
          }}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}

      {isDeleted ? (
        <SweetAlert
          success
          title={"Customer deleted!"}
          onConfirm={() => toggleDeleted(false)}
        >
          Successfully deleted!
        </SweetAlert>
      ) : null}
    </AUX>
  );
};

const mapStatetoProps = (state) => {
  return {
    loading: state.customer.loading,
    customers: state.customer.data,
    error: state.customer.error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCustomers: () => dispatch(getCustomers()),
  deleteCustomer: (cust) => dispatch(deleteCustomer(cust)),
});

export default withRouter(
  connect(
    mapStatetoProps,
    mapDispatchToProps
  )(CustomersList)
);
