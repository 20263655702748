import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AUX from "../../hoc/Aux_";
import { getAds, uploadAd, getUploadedFile } from "../../store/actions/ad.actions";
import { ToastContainer, toast } from 'react-toastify';

const AdsPage = ({ loading, ads, error, upImages, getAds, uploadAd, getUploadedFile }) => {
  const [popup, setPopup] = useState(null);

  useEffect(() => {
    getAds();
  }, []);

  useEffect(() => {
    if(ads && ads.length > 0) {
      ads.forEach(item => {
        const body = {
          keyName: item.keyName,
          id: item.id
        }
        getUploadedFile(body);
      })
    }
  }, [ads]);

  const handleImage = async (file, ad) => {
    try {
      const body = {
        "fileName": file.name.substring(0, file.name.lastIndexOf('.')),
        "fileExtension": file.type,
        "fileObj": file,
        "adObj": { ...ad }
      };
      await uploadAd(body);

    } catch (error) {
      toast.error("Error, upload failed", {
        toastId: "toast-id",
        hideProgressBar: true,
        theme: "colored",
        closeButton: true,
      });
    }
  };

  const getPhoto = (id) => {
    let data = upImages.find(item => item.id === id);
    return (data && data.url) ? data.url : null;
  };

  return (
    <AUX>
      <div>
        <ToastContainer style={{ ['--toastify-toast-min-height']: "40px" }} />
      </div>
      {popup ? (
        <Lightbox
          mainSrc={popup.url}
          onCloseRequest={() => setPopup(null)}
          imageCaption={popup.caption}
          zoomInLabel="Zoom in"
        />
      ) : null}

      {loading ? (
        <div id="status">
          <div className="spinner" />
        </div>
      ) : (
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              {ads && typeof (ads) === 'object'
                ? ads.map((ad, index) => {
                  if (index < 9 && index < ads.length) {
                    return (
                      <div className="col-lg-4">
                        <div className="card m-b-20">
                          <div className="card-body">
                            <h4 className="mt-0 header-title">Level {ad.priority}</h4>
                            <p className="text-muted m-b-30 font-14" />
                            <div className="form-group">
                              <div className="input-group align-items-center">
                                <img
                                  src={
                                    getPhoto(ad.id) || "assets/images/gallery/work-1.jpg"
                                  }
                                  style={{ height: "120px", width: "200px", objectFit: "cover" }}
                                  alt="Image"
                                  className="img-fluid"
                                  onClick={() => {
                                    let url = getPhoto(ad.id) || "assets/images/gallery/work-1.jpg";
                                    setPopup({
                                      url,
                                      caption: "Exterior Image (Left)",
                                    });
                                  }}
                                />
                              </div>
                              <div className="custom-file mt-1">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id={`inputFile${ad.priority}`}
                                  aria-describedby="inputGroupFileAddon01"
                                  accept="image/png, image/jpeg"
                                  value=""
                                  onChange={(e) => {
                                    const files = e.target.files;
                                    if (files && files.length) {
                                      handleImage(files[0], ad);
                                    }
                                  }}
                                />
                                <label className="custom-file-label" htmlFor="inputGroupFile01">
                                  Choose New
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className="col-lg-4 d-none">
                        <div className="card m-b-20">
                          <div className="card-body">
                            <h4 className="mt-0 header-title">Privacy Policy Document</h4>
                            <p className="text-muted m-b-30 font-14" />
                            <div className="form-group">
                              <div className="input-group align-items-center">
                                <img
                                  src="assets/images/gallery/work-1.jpg"
                                  style={{ height: "120px", width: "200px", objectFit: "cover" }}
                                  alt="Image"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="custom-file mt-1">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id={`inputFile${ad.priority}`}
                                  aria-describedby="inputGroupFileAddon01"
                                  accept=".pdf,.docx"
                                  value=""
                                  onChange={(e) => {
                                    const files = e.target.files;
                                    if (files && files.length) {
                                      handleImage(files[0], ad.priority);
                                    }
                                  }}
                                />
                                <label className="custom-file-label" htmlFor="inputGroupFile01">
                                  Choose New Document
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })
                : null}
            </div>
          </div>
        </div>
      )}
    </AUX>
  );
};

const mapStatetoProps = (state) => {
  return {
    loading: state.ad.loading,
    ads: state.ad.data,
    error: state.ad.errors,
    upImages: state.ad.images
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAds: () => dispatch(getAds()),
  uploadAd: (body) => dispatch(uploadAd(body)),
  getUploadedFile: (body) => dispatch(getUploadedFile(body))
});

export default withRouter(
  connect(
    mapStatetoProps,
    mapDispatchToProps
  )(AdsPage)
);
