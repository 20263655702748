const appInitialState = {
  user: {
    loading: false,
    error: null,
    data: {},
  },
  customer: {
    loading: false,
    error: null,
    data: [],
  },
  driver: {
    loading: false,
    error: null,
    data: [],
    carTypes: [],
    documents: []
  },
  ride: {
    loading: false,
    error: null,
    data: [],
  },
  config: {
    loading: false,
    error: null,
    data: {},
  },
  ad: {
    loading: false,
    error: null,
    data: [],
    images: []
  },
};

export default appInitialState;
