import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import AUX from "../../hoc/Aux_";
import { login, logout } from "../../store/actions/user.actions";
import ROUTES from "../../routes/constant.route";
import { ToastContainer, toast } from 'react-toastify';

const LoginPage = (props) => {
  const { loading, user, error, login, history, logout } = props;

  const [formData, setFormData] = useState({});
  const [validationError, setValidationError] = useState("");

  const [isPasswordVisible, togglePassword] = useState(false);

  useEffect(() => {
    if (user && user.id) {
      history.push(ROUTES.CUSTOMERS.path);
    }
  }, [user]);

  const onDataChange = (text, type) => {
    formData[type] = text;
    setFormData(formData);
  };

  const validateSubmit = (e) => {
    e.preventDefault();
    setValidationError("");
    try {
      if (!formData.email) throw "Email is required";
      if (!formData.password) throw "Password is required";
      login(formData);
    } catch (e) {
      setValidationError(e);
    }
  };

  const renderError = (error) => {
    return (
      <div className="alert alert-danger alert-dismissible mb-0 fade show" role="alert">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        {error}
      </div>
    );
  };

  // const togglePassword = () => {
  //   togglePassword(!isPasswordVisible)
  //   // isPasswordVisible = !isPasswordVisible;
  // }

  return (
    <AUX>
      <div className="accountbg" />
      <div>
        <ToastContainer style={{ ['--toastify-toast-min-height']: "40px" }} />
      </div>
      <div className="wrapper-page">
        <div className="card">
          <div className="card-body">
            <h3 className="text-center m-0">
              <Link to="/" className="logo logo-admin">
                <img src="assets/images/logo.png" height="80" alt="logo" />
              </Link>
            </h3>

            <div className="p-3">
              <h4 className="font-18 m-b-5 text-center">Welcome Back !</h4>
              <p className="text-muted text-center">Sign in to continue to Admin Area.</p>

              {validationError ? renderError(validationError) : null}

              <form className="form-horizontal m-t-30" onSubmit={(e) => validateSubmit(e)}>
                <div className="form-group">
                  <label htmlFor="username">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder="Enter email"
                    onChange={(e) => {
                      onDataChange(e.target.value, "email");
                    }}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="userpassword">Password</label>
                  <div className="input-group mb-3">
                    <input
                      type={isPasswordVisible ? 'text' : 'password'}
                      className="form-control"
                      id="userpassword"
                      placeholder="Enter password"
                      onChange={(e) => {
                        onDataChange(e.target.value, "password");
                      }}
                    />
                    <div className="input-group-append cursor-pointer" onClick={() => togglePassword(!isPasswordVisible)}>
                      <span className="input-group-text bg-white" id="basic-addon2">
                        {
                          isPasswordVisible ? <i className="fa fa-eye" aria-hidden="true"></i> :
                            <i className="fa fa-eye-slash" aria-hidden="true"></i>
                        }
                      </span>
                    </div>
                  </div>
                </div>

                <div className="form-group row m-t-20">
                  <div className="col-sm-6">
                    {/* <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="customControlInline" />
                      <label className="custom-control-label" htmlFor="customControlInline">
                        Remember me
                      </label>
                    </div> */}
                  </div>
                  <div className="col-sm-6 text-right">
                    <button className="btn btn-primary w-md waves-effect waves-light" type="submit">
                      {loading ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                      ) : (
                        "Log In"
                      )}
                    </button>
                  </div>
                </div>

                {/* <div className="form-group m-t-10 mb-0 row">
                  <div className="col-12 m-t-20">
                    <Link to="pages_recoverpw" className="text-muted">
                      <i className="mdi mdi-lock" /> Forgot your password?
                    </Link>
                  </div>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </AUX>
  );
};

const mapStatetoProps = (state) => {
  return {
    loading: state.user.loading,
    user: state.user.data,
    error: state.user.error,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    login: (credentials) => dispatch(login(credentials)),
    logout: () => dispatch(logout()),
  };
};

export default withRouter(
  connect(
    mapStatetoProps,
    mapDispatchtoProps
  )(LoginPage)
);
