import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import AuthLayout from "./components/Layout/AuthLayout";
import MainLayout from "./components/Layout/MainLayout";

class App extends Component {
  render() {
    return (
      <Switch>
        <Route path="/login" exact component={AuthLayout} />
        <Route path="/" component={MainLayout} />
      </Switch>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({});

const mapStatetoProps = (state) => {
  return {};
};

export default withRouter(
  connect(
    mapStatetoProps,
    mapDispatchToProps
  )(App)
);
